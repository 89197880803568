import { useContext, useState, useEffect, ReactNode } from "react";
import { NavMenu } from "../NavMenu/NavMenu";
import { ErrorBoundary } from "../Shared/ErrorBoundary";
import { themeContext } from "../contexts/ThemeProvider";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";

interface ILayoutProps {
  children: ReactNode;
}
export const Layout = ({ children }: ILayoutProps) => {
  // for css dark mode toggle store
  const themectx = useContext(themeContext);
  const machineDarkPreference = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const [defaultDark] = useState(machineDarkPreference);
  const [themeData, setThemeData] = useState(
    machineDarkPreference ? "dark" : "light"
  );

  useEffect(() => {
    localStorage.setItem("theme", defaultDark ? "dark" : "light");
    setThemeData(defaultDark ? "dark" : "light");
    themectx.updateThemeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDark]);

  return (
    <Box id="mainContainer" data-theme={themeData}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <ErrorBoundary localErrorMsg="The app is not loading at the moment">
            <NavMenu data-theme={themeData} mainContent={children} />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Box>
  );
};
