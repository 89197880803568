import { Card, Grid } from "@mui/material";
import { useState } from "react";
import { ModalDialog } from "../Shared/ModalDialog";
import { FeedbackButton } from "./FeedbackButton";

export const FeedbackForm = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <FeedbackButton handleClickOpen={handleClickOpen} />
      <ModalDialog
        title="Provide Feedback"
        onClose={() => setOpen(false)}
        show={open}
      >
        <div>
          Your feedback is important to us and helps us understand how to
          improve.
          <Grid container>
            <Grid item xs={4}>
              <Card>Feedback about the page</Card>
            </Grid>
            <Grid item xs={4}>
              <Card>Feedback about the app</Card>
            </Grid>
            <Grid item xs={4}>
              Request a demo
            </Grid>
          </Grid>
        </div>
      </ModalDialog>
    </>
  );
};
