import { PieChart, Pie, ResponsiveContainer } from "recharts";

interface ITimeEntryPieChartProps {
  entries: any;
}
export const TimeEntryPieChart = ({ entries }: ITimeEntryPieChartProps) => {
  const renderLabel = (entry: any) => {
    return `${entry.dayOfWeek} ${entry.count}`;
  };

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart height={200} width={200}>
          <Pie
            data={entries}
            dataKey="count"
            cx="50%"
            cy="50%"
            outerRadius={50}
            fill="var(--pie-chart-color1)"
            label={renderLabel}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
