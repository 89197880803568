import { timeApi } from "../../../api/generated/applicationClient";
import { MetricDisplay } from "./MetricDisplay";

interface TimeEntryMetricNumberOfEntriesDisplayProps {
  entries: timeApi.TimeEntry[];
}
export const TimeEntryMetricNumberOfEntriesDisplay = ({
  entries,
}: TimeEntryMetricNumberOfEntriesDisplayProps) => {
  return (
    <MetricDisplay
      metricUnit="Entries"
      metricValue={entries.length.toString()}
    />
  );
};
