import { ProjectFormEdit } from "./ProjectFormEdit";
import { ProjectDeleteButton } from "./ProjectDeleteButton";
import Moment from "react-moment";
import { ErrorBoundary } from "../Shared/ErrorBoundary";
import { Box, CircularProgress } from "@mui/material";
import { timeApi } from "../../api/generated/applicationClient";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface IProjectValuesTableProps {
  entries: any;
  callback: () => any;
}
export const ProjectValuesTable = ({
  entries,
  callback,
}: IProjectValuesTableProps) => {
  const renderEntriesTable = (entries: timeApi.Project[]) => {
    if (entries !== undefined && entries !== null) {
      return (
        <TableContainer component={Paper}>
          <Table aria-label="Invoices">
            <TableHead>
              <TableRow>
                <TableCell>Project</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((project: timeApi.Project) => (
                <TableRow key={project.projectId}>
                  <TableCell>
                    <Box>
                      {project.client?.name} ({project.client?.code})
                    </Box>
                    <Box>
                      {project.name} ({project.code})
                    </Box>
                    <Box>{project.timeEntryCount} Time Entries</Box>
                    <Box>
                      <strong>Created on </strong>
                      <Moment format="dddd MM/DD/YYYY">
                        {project.createdOn}
                      </Moment>
                    </Box>
                    <Box style={{ paddingTop: `5px` }}>
                      {project.description}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <ProjectFormEdit entry={project} callback={callback} />
                  </TableCell>
                  <TableCell>
                    <ProjectDeleteButton
                      entryId={project.projectId?.toString()}
                      callback={callback}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return <CircularProgress color="inherit" />;
    }
  };

  let contents = renderEntriesTable(entries);

  return (
    <ErrorBoundary>
      <div>{contents}</div>
    </ErrorBoundary>
  );
};
