import { Backdrop, CircularProgress, Stack } from "@mui/material";
interface LoadingBackdropProps {
  message?: string;
}
export const LoadingBackDrop = ({
  message = "Loading Time Entries...",
}: LoadingBackdropProps) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Stack alignItems="center" spacing={2}>
        <h2>{message}.</h2>

        <CircularProgress variant="indeterminate" />
      </Stack>
    </Backdrop>
  );
};
