import { DeleteButton } from "../Shared/DeleteButton";

interface InvoiceDeleteButtonProps {
  entryId: number;
  callback: () => void;
}

export const InvoiceDeleteButton = ({
  entryId,
  callback,
}: InvoiceDeleteButtonProps) => {
  const apiData = { path: "api/invoices/" + entryId };

  return (
    <DeleteButton
      apiData={apiData}
      modalTitle="Delete Invoice"
      callback={callback}
    />
  );
};
