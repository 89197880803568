import { DeleteButton } from "../Shared/DeleteButton";
interface UserDeleteButtonProps {
  userId: string;
  callback: () => any;
}
export const UserDeleteButton = ({
  userId,
  callback,
}: UserDeleteButtonProps) => {
  const apiData = { path: `api/applicationuser/${userId}` };

  return (
    <DeleteButton
      apiData={apiData}
      modalTitle="Delete User"
      callback={callback}
    />
  );
};
