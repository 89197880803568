import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import AuthorizeRoute from "../../api/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "../../api/api-authorization/ApiAuthorizationRoutes";

// app components
import { TimeEntryDashboard } from "../TimeEntry/TimeEntryDashboard";
import { About } from "../Pages/About";
import { Invoices } from "../Invoices/Invoices";
import { Clients } from "../Clients/Clients";
import { Projects } from "../Projects/Projects";
import { Users } from "../Users/Users";
import { Reports } from "../Reports/Reports";
import { WeeklyTime } from "../TimeEntry/WeeklyTime";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
      </Routes>
      <AuthorizeRoute path="/TimeEntry" element={<TimeEntryDashboard />} />
      <AuthorizeRoute path="/Invoices" element={<Invoices />} />
      <AuthorizeRoute path="/Clients" element={<Clients />} />
      <AuthorizeRoute path="/Projects" element={<Projects />} />
      <AuthorizeRoute path="/About" element={<About />} />
      <AuthorizeRoute path="/Users" element={<Users />} />
      <AuthorizeRoute path="/Reports" element={<Reports />} />
      <AuthorizeRoute path="/WeeklyTime" element={<WeeklyTime />} />
      <ApiAuthorizationRoutes />
    </>
  );
};

export default AppRoutes;
