import { FaFilter, FaList, FaThLarge } from "react-icons/fa";
import { SelectMonth } from "../Shared/SelectMonth";
import { SelectYear } from "../Shared/SelectYear";
import { IsMobile } from "../../common/MobileCheck";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Grid } from "@mui/material";

interface ITimeEntryFilters {
  month: string;
  year: number;
  filterBy: string;
  viewListOrCard: string;
  onMonthChange: (month: string) => any;
  onYearChange: (year: number) => any;
  onViewChange: (listOrCard: string) => void;
  onFilterByChange: (monthOrYear: string) => any;
}

export const TimeEntryFilters = (props: ITimeEntryFilters) => {
  const onMonthChange = (newMonthSelected: string) => {
    props.onYearChange(Number(newMonthSelected.substring(0, 4)));
    props.onMonthChange(newMonthSelected.substring(5, 7));
  };

  const onYearChange = (newYearSelected: string) => {
    props.onYearChange(Number(newYearSelected));
  };

  return (
    <Grid container>
      <Grid xs={12} item>
        <Grid item style={{ paddingRight: "10px" }}>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={props.filterBy}
            exclusive
            onChange={(event, value) => props.onFilterByChange(value)}
          >
            <ToggleButton value="0" disabled={true}>
              <FaFilter />
            </ToggleButton>
            <ToggleButton value="month">Month</ToggleButton>
            <ToggleButton value="year">Year</ToggleButton>
          </ToggleButtonGroup>
          {props.filterBy === "month" ? (
            <>
              <SelectMonth
                selectedMonth={props.month}
                selectedYear={props.year}
                callback={onMonthChange}
              />
            </>
          ) : props.filterBy === "year" ? (
            <>
              <SelectYear year={props.year} callback={onYearChange} />
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          <span style={{ float: "right" }}>
            {!IsMobile() ? (
              <ToggleButtonGroup
                color="primary"
                value={props.viewListOrCard}
                exclusive
                onChange={(event, value) => props.onViewChange(value)}
              >
                <ToggleButton value="list">
                  <FaList />
                </ToggleButton>
                <ToggleButton value="card">
                  <FaThLarge />
                </ToggleButton>
              </ToggleButtonGroup>
            ) : (
              <></>
            )}
          </span>
        </Grid>
        <Grid item style={{ paddingTop: "10px" }}></Grid>
      </Grid>
    </Grid>
  );
};
