import { ErrorBoundary } from "../Shared/ErrorBoundary";
import { CircularProgress } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { timeApi } from "../../api/generated/applicationClient";
import { InvoiceValuesTableRow } from "./InvoiceValuesTableRow";

interface InvoiceValuesTableProps {
  entries: timeApi.Invoice[];
  callback: () => void;
}

export const InvoiceValuesTable = ({
  entries,
  callback,
}: InvoiceValuesTableProps) => {
  return !entries ? (
    <CircularProgress />
  ) : (
    <ErrorBoundary>
      <TableContainer component={Paper}>
        <Table aria-label="Invoices">
          <TableHead>
            <TableRow>
              <TableCell>Invoice</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.reverse().map((entry) => (
              <InvoiceValuesTableRow
                key={entry.invoiceId}
                entry={entry}
                callback={() => callback()}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ErrorBoundary>
  );
};
