import { Grid } from "@mui/material";
import { TimeEntryCard } from "./TimeEntryCard";
import { timeApi } from "../../api/generated/applicationClient";

interface ITimeEntryValuesCardViewProps {
  entries: timeApi.TimeEntry[];
  callback: () => any;
}

export const TimeEntryValuesCardView = ({
  entries,
  callback,
}: ITimeEntryValuesCardViewProps) => {
  const display = entries.map((entry) => {
    return (
      <Grid item xs={12} md={9} key={entry.timeEntryId}>
        <TimeEntryCard entry={entry} callback={callback}></TimeEntryCard>
      </Grid>
    );
  });

  return <Grid container>{display}</Grid>;
};
