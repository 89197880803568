import { Helmet } from "react-helmet";
import { Layout } from "./AppLayout";
import "./App.css";
import AppContext from "./AppContext";
import AppRoutes from "./AppRoutes";

const App = () => {
  return (
    <AppContext>
      <Helmet>
        <title>Time Entry</title>
        <link rel="shortcut icon" href="favicon.svg" />
      </Helmet>
      <Layout>
        <AppRoutes />
      </Layout>
    </AppContext>
  );
};

export default App;
