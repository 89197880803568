import { Box, Button, Grid } from "@mui/material";

interface ISaveCancelButtonsProps {
  onSave: () => Promise<void>;
  onCancel: any;
  disableSave: boolean;
}

export const SaveCancelButtons = ({
  onSave,
  onCancel,
  disableSave,
}: ISaveCancelButtonsProps) => {
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <Button
            id="btn_saveentry"
            variant="contained"
            onClick={onSave}
            disabled={disableSave}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button id="btn_closenewentry" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
