import { Box, Button, DialogContentText, Grid } from "@mui/material";
import { ModalDialog } from "../../Shared/ModalDialog";
import { TimeEntryApi } from "../../../api/api-timeentries";

interface ITimeEntryDeleteButtonModalProps {
  show: boolean;
  value: any;
  callback: () => any;
  callbackCloseForm: () => any;
}
export const TimeEntryDeleteButtonModal = ({
  show,
  value,
  callback,
  callbackCloseForm,
}: ITimeEntryDeleteButtonModalProps) => {

  const deleteEntry = async () => {
    const datasource = new TimeEntryApi();
    datasource.DeleteTimeEntry(value, callback);
    callbackCloseForm();
  };

  return (
    <>
      <Box>
        <ModalDialog
          title="User Details"
          onClose={() => callbackCloseForm()}
          show={show}
        >
          <DialogContentText>
            Are you sure you want to delete?
          </DialogContentText>

          <Grid container>
            <Grid item xs={4}>
              <Button variant="contained" onClick={deleteEntry}>
                Delete
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  callbackCloseForm();
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </ModalDialog>
      </Box>
    </>
  );
};
