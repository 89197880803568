import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
interface IFloatingAddButtonProps {
  onButtonClick: () => any;
}
export const FloatingAddButton = (props: IFloatingAddButtonProps) => {
  return (
    <Fab
      id="button-add"
      color="primary"
      aria-label="add"
      style={{
        position: "fixed",
        bottom: "5%",
        right: "5%",
        opacity: ".65",
      }}
      onClick={() => props.onButtonClick()}
    >
      <AddIcon />
    </Fab>
  );
};
