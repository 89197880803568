import { Box, Button } from "@mui/material";

interface IFeedbackButtonProps {
  handleClickOpen: () => void;
}

export const FeedbackButton = (props: IFeedbackButtonProps) => {
  return (
    <Box
      sx={{
        rotate: "270deg",
        position: "fixed",
        bottom: "25%",
        left: "78%",
        opacity: ".65",
        whiteSpace: "nowrap",
        minWidth: "auto",
      }}
    >
      <Button variant="contained" onClick={props.handleClickOpen}>
        Feedback / Issues
      </Button>
    </Box>
  );
};
