import { useState } from "react";
import authService from "../../api/api-authorization/AuthorizeService";
import axios from "axios";
import { ClientsSelectList } from "../Clients/ClientsSelectList";
import { Box, FormControl, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ClientContext } from "../contexts/ClientProvider";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
import { ModalDialog } from "../Shared/ModalDialog";
import { timeApi } from "../../api/generated/applicationClient";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { FloatingAddButton } from "../Shared/FloatingAddButton";

interface IInvoiceFormEntryProps {
  callback: () => any;
}
export const InvoiceFormEntry = (props: IInvoiceFormEntryProps) => {
  const d = new Date();
  const d2 = moment(new Date()).add(30, 'd').toDate();
  const [invoiceDate, setInvoiceDate] = useState(d);
  const [dueDate, setDueDate] = useState(d2);
  const [totalAmount, setTotalAmount] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [description, setDescription] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [clientId, setClientId] = useState<number>();
  const [client, setClient] = useState<timeApi.Client>();

  const selectClient = (client: timeApi.Client) => {
    setClientId(client.clientId);
    setClient(client);
  };

  const saveEntry = async () => {
    let d = new Date();
    d.toISOString();

    const data = {
      TotalAmount: totalAmount,
      AmountPaid: amountPaid,
      //name: name,
      DescriptionOfWork: description,
      ClientId: clientId,
      createdByUserId: "jedgemis@gmail.com",
      InvoiceDate: invoiceDate,
      DueDate: dueDate,
      // CreatedOn: `${d.getMonth()}/${d.getDate()}/${d.getFullYear()}`,
    };

    const [user, token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);
    axios
      .post("api/invoices", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          essUsername: user.name,
        },
      })
      .then(() => {
        setDescription("");
        setShowForm(false);

        props.callback();
      });
  };

  const invoiceDateSelected = (event: Date | null) => {
    const d = new Date(event ?? ``);
    const d2 = moment(d).add(30, 'd').toDate();
    setInvoiceDate(d);
    setDueDate(d2);
  };

  return (
    <div>
      <FloatingAddButton onButtonClick={() => setShowForm(true)} />
      <ModalDialog
        title="New Invoice"
        onClose={() => setShowForm(false)}
        show={showForm}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 2, width: "auto" },
          }}
        >
          <h1>Invoice Entry</h1>
          <div className="form-group">
            <label>Client</label>
            <ClientContext.Consumer>
              {(clientContext) => (
                <ClientsSelectList
                  client={client}
                  callback={selectClient}
                  allClients={clientContext.clientData}
                />
              )}
            </ClientContext.Consumer>
          </div>
          <div>
            <FormControl required>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Invoice Date"
                  value={invoiceDate.toString()}
                  onChange={(
                    value: String | null,
                    keyboardInputValue?: string | undefined
                  ) => {
                    invoiceDateSelected(moment(value?.toString()).toDate());
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div>
            <FormControl required>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Due Date"
                  value={dueDate.toString()}
                  onChange={(
                    value: String | null,
                    keyboardInputValue?: string | undefined
                  ) => {
                    setDueDate(moment(value?.toString()).toDate());
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div className="form-group">
            <TextField
              required
              id="totalAmount"
              label="Total Amount"
              type="number"
              value={totalAmount}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setTotalAmount(e.target.value)}
            />
          </div>
          <div>
            <TextField
              required
              id="amountPaid"
              label="Amount Paid"
              type="number"
              value={amountPaid}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setAmountPaid(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="description"
              label="Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <br />
          <SaveCancelButtons
            onSave={saveEntry}
            onCancel={() => setShowForm(false)}
            disableSave={false}
          />
        </Box>
      </ModalDialog>
    </div>
  );
};
