import { Routes } from "react-router-dom";
import { Route } from "react-router";
import { Login } from "./Login";
import { Logout } from "./Logout";
import {
  ApplicationPaths,
  LoginActions,
  LogoutActions,
} from "./ApiAuthorizationConstants";

const ApiAuthorizationRoutes = () => {
  return (
    <Routes>
      <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
        <Route
          path={ApplicationPaths.Login}
          element={<Login action={LoginActions.Login}></Login>}
        />
        <Route
          path={ApplicationPaths.LoginFailed}
          element={<Login action={LoginActions.LoginFailed}></Login>}
        />
        <Route
          path={ApplicationPaths.LoginCallback}
          element={<Login action={LoginActions.LoginCallback}></Login>}
        />
        <Route
          path={ApplicationPaths.Profile}
          element={<Login action={LoginActions.Profile}></Login>}
        />
        <Route
          path={ApplicationPaths.Register}
          element={<Login action={LoginActions.Register}></Login>}
        />
        <Route
          path={ApplicationPaths.LogOut}
          element={<Logout action={LogoutActions.Logout}></Logout>}
        />
        <Route
          path={ApplicationPaths.LogOutCallback}
          element={<Logout action={LogoutActions.LogoutCallback}></Logout>}
        />
        <Route
          path={ApplicationPaths.LoggedOut}
          element={<Logout action={LogoutActions.LoggedOut}></Logout>}
        />
      </Route>
    </Routes>
  );
};
export default ApiAuthorizationRoutes;
