import { Button, Typography } from "@mui/material";
import { EntryRow } from "./EntryRow";
import authService from "../../../api/api-authorization/AuthorizeService";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { timeApi } from "../../../api/generated/applicationClient";
import moment from "moment";

export interface EntryTableProps {
  weekStartDate: string | null;
  callback: (hoursWorked: number) => any;
}

export const EntryTable = (props: EntryTableProps) => {
  const [entries, setEntries] = useState<timeApi.WeeklyTimeEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    await populateEntriesByDateTable(props.weekStartDate ?? new Date(Date.now()).toString());
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.weekStartDate]);

  const onDeleteEntry = (index: number) => {
    // setEntryRows(entries.filter((entry) => entry.props.index !== index));
  };

  const populateEntriesByDateTable = async (inputDate: string) => {
    const [user, token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);

    const response = await fetch("api/timeentriesbyweek?date=" + inputDate, {
      headers: { Authorization: `Bearer ${token}`, essUsername: user.name },
    });

    const data = await response.json();
    setEntries(data);
    setLoading(false);
  };

  const onAddEntryRow = () => {
    const newRowCount = entries.length + 1;

    // add logic to add new entry
    // setEntryRows(
    //   entryRows.concat(
    //     <EntryRow
    //       key={newRowCount}
    //       index={newRowCount}
    //       onDelete={() => onDeleteEntry}
    //     ></EntryRow>
    //   )
    // );
  };

  if (loading) {
    return <CircularProgress color="inherit" />;
  } else if (entries.length === 0) {
    return <div>No entries found for this week</div>;
  }
  return (
    <div>
      <table className="entryTable">
        <thead>
          <tr>
            <th>Project</th>
            <th>
              <Typography className="dateLabel">
                Monday {moment(entries[0].mondayDate).format("MM/d/YYYY")}
              </Typography></th>
            <th>
              <Typography className="dateLabel">
                Tuesday {moment(entries[0].tuesdayDate).format("MM/d/YYYY")}
              </Typography>
            </th>
            <th>
              <Typography className="dateLabel">
                Wednesday {moment(entries[0].wednesdayDate).format("MM/d/YYYY")}
              </Typography>
            </th>
            <th>
              <Typography className="dateLabel">
                Thursday {moment(entries[0].thursdayDate).format("MM/d/YYYY")}
              </Typography>
            </th>
            <th>
              <Typography className="dateLabel">
                Friday {moment(entries[0].fridayDate).format("MM/d/YYYY")}
              </Typography>
            </th>
            <th>
              <Typography className="dateLabel">
                Saturday {moment(entries[0].saturdayDate).format("MM/d/YYYY")}
              </Typography>
            </th>
            <th>
              <Typography className="dateLabel">
                Sunday {moment(entries[0].sundayDate).format("MM/d/YYYY") }
              </Typography>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            entries.map((entry, i) => (
              <EntryRow
                key={entry?.project?.projectId}
                index={i}
                entry={entry}
                onDelete={() => onDeleteEntry}
              ></EntryRow>
            ))
          }</tbody>
      </table>
      <Button variant="contained" onClick={onAddEntryRow}>
        Add Entry
      </Button>
    </div>
  );
};
