/* eslint-disable no-unused-vars */
import { useEffect, useState, useCallback } from "react";
import { TimeEntryValuesCardView } from "../TimeEntry/TimeEntryValuesCardView";
import { TimeEntryForm } from "../TimeEntry/TimeEntryForm";
import { TimeEntryValuesTable } from "../TimeEntry/TimeEntryValuesTable";
import TimeEntryMetricLabels from "../TimeEntry/Metrics/TimeEntryMetricLabels";
import { getCurrentMonthPadded } from "../../common/DateHelper";
import { TimeEntryApi } from "../../api/api-timeentries";
import { useTitle } from "../../hooks/useTitle";
import { TimeEntryFilters } from "../TimeEntry/TimeEntryFilters";
import { Feedback } from "../Feedback/Feedback";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { LoadingBackDrop } from "../Shared/LoadingBackdrop";
import { timeApi } from "../../api/generated/applicationClient";
import { useAnalytics } from "../../hooks/useAnalytics";
import { TimeEntryBarChart } from "../TimeEntry/Charts/TimeEntryBarChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TimeEntryPieChart } from "../TimeEntry/Charts/TimeEntryPieChart";
import { TimeEntryRadarChart } from "../TimeEntry/Charts/TimeEntryRadarChart";

export const Reports = () => {

  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState<timeApi.TimeEntry[]>([]);
  const [entriesByDayOfWeek, setEntriesByDayOfWeek] = useState<any[]>([]);
  const [monthSelected, setMonthSelected] = useState(`All`); // useState(getCurrentMonthPadded);
  const [yearSelected, setYearSelected] = useState(2024);
  const [showCardView, setShowCardView] = useState(true);
  const [filterBy, setFilterBy] = useState(`year`);

  useTitle(`Time Entry`);
  useAnalytics();

  const handleFilterByChange = (val: string) => {
    console.log(val);
    if (val === "month") {
      setFilterBy("month");
      setMonthSelected(getCurrentMonthPadded());
      setYearSelected(new Date().getFullYear());
    }
    if (val === "year") {
      setFilterBy("year");
      setMonthSelected("All");
    }
    if (val === "all") {
      setFilterBy("all");
    }
  };

  const reloadTable = useCallback(async () => {
    setLoading(true);
    const datasource = new TimeEntryApi();
    const refreshedEntries = await datasource.GetTimeEntries(
      monthSelected,
      yearSelected
    );
    const refreshedEntriesByDayOfWeek = await datasource.GetTimeEntriesPerDay(
      monthSelected,
      yearSelected.toString(),
      null
    );
    setEntriesByDayOfWeek(refreshedEntriesByDayOfWeek);
    setEntries(refreshedEntries);
    setLoading(false);
  }, [monthSelected, yearSelected]);

  useEffect(() => {
    reloadTable();
  }, [yearSelected, monthSelected, reloadTable]);

  const onMonthChange = (newMonthSelected: string) => {
    setMonthSelected(newMonthSelected);
  };

  const onYearChange = (newYearSelected: number) => {
    setYearSelected(newYearSelected);
  };

  const onViewChange = (viewName: string) => {
    const showCardView = viewName === `card` ? true : false;
    setShowCardView(showCardView);
  };

  return loading ? (
    <LoadingBackDrop />
  ) : (
    <Box>
      <Box>
        <Feedback />
        <TimeEntryMetricLabels entries={entries} />
        <Grid container>
          <Grid item>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Charts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <TimeEntryPieChart entries={entriesByDayOfWeek} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TimeEntryRadarChart entries={entriesByDayOfWeek} />
                  </Grid>
                  <Grid item xs={12}>
                    <TimeEntryBarChart entries={entries} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <br />
      </Box>
      <Box>
        <TimeEntryFilters
          month={monthSelected}
          year={yearSelected}
          onMonthChange={onMonthChange}
          onYearChange={onYearChange}
          onViewChange={onViewChange}
          filterBy={filterBy}
          onFilterByChange={handleFilterByChange}
          viewListOrCard={showCardView ? `card` : `list`}
        />
      </Box>
      <Box>
        <Box>
          <TimeEntryForm callback={reloadTable} />
          <br />
        </Box>
      </Box>
      <Box>
        {showCardView ? (
          <TimeEntryValuesCardView entries={entries} callback={reloadTable} />
        ) : (
          <TimeEntryValuesTable entries={entries} />
        )}
      </Box>

      <br />
      <br />
    </Box>
  );
};
