import { useState } from "react";
import { FormControl, MenuItem, InputLabel, Select } from "@mui/material";
import { timeApi } from "../../api/generated/applicationClient";

interface IClientsSelectList {
  client: timeApi.Client | undefined | null;
  allClients: timeApi.Client[];
  callback: (c: timeApi.Client) => any;
}
export const ClientsSelectList = ({
  client,
  allClients,
  callback,
}: IClientsSelectList) => {
  const [selectedClient, setSelectedClient] = useState({
    value: client ? client.clientId : ``,
    label: client?.name ?? ``,
  });

  const [selectOptions] = useState(
    allClients.map((c: timeApi.Client) => ({
      value: c.clientId,
      label: c.name,
    }))
  );

  const handleChange = (e: any) => {
    // e.preventDefault();
    // e.stopPropogation();
    const fClient = [...allClients].filter(function (i) {
      return i.clientId === Number(e.target.value);
    })[0];

    setSelectedClient({ value: fClient.clientId, label: fClient.name ?? `` });
    callback(fClient);
  };

  return (
    <div style={{ width: "230px" }}>
      <FormControl required fullWidth>
        <InputLabel id="client-select-label">Client</InputLabel>
        <Select
          labelId="client-select-label"
          id="client-select"
          label="Client"
          onChange={(e) => handleChange(e)}
          value={selectedClient.value.toString()}
        >
          {selectOptions.map((client) => (
            <MenuItem key={client.value} value={client.value}>
              {client.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
