import { useEffect } from "react";

export function useAnalytics() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production")
      // Implement Analytics
      // ReactGA.send(window.location.pathname + window.location.search);
      console.log(`Visited ${window.location} ${window.location.search}`);
      
  }, []);
}
