import { timeApi } from "./generated/applicationClient";
import { RestService } from "./rest-service";

export class ProjectApi {
  restService: RestService;
  apiClient: timeApi.ProjectsClient;
  constructor() {
    this.restService = new RestService();
    this.apiClient = new timeApi.ProjectsClient(
      `${window.location.protocol}//${window.location.host}`,
      this.restService.axiosInstance
    );
  }

  async GetAllProjects(callback: any) {
    this.restService = new RestService();
    await this.restService.GetAxiosInstance();
    this.apiClient = new timeApi.ProjectsClient(
      `${window.location.protocol}//${window.location.host}`,
      this.restService.axiosInstance
      );
      if (this.restService.user) {
          const projects = await this.apiClient.getProjects(
              this.restService.user.name
          );
          return callback(projects);
      } else {
          return callback([])
      }
  }

  async UpdateProject(
    projectId: number,
    callback: any,
    code: string,
    name: string,
    description: string,
    clientId: number,
    pivotalProjectId: string,
    pivotalXToken: string
  ) {
    const url = `api/projects/${projectId}`;
    const data = [
      {
        "path": "/projectId",
        "op": "replace",
        "value": projectId,
      },
      {
        "path": "/code",
        "op": "replace",
        "value": code,
      },
      {
        "path": "/name",
        "op": "replace",
        "value": name,
      },
      {
        "path": "/description",
        "op": "replace",
        "value": description,
      },
      {
        "path": "/clientId",
        "op": "replace",
        "value": clientId,
      },
      {
        "path": "/pivotalProjectId",
        "op": "replace",
        "value": pivotalProjectId,
      },
      {
        "path": "/pivotalXToken",
        "op": "replace",
        "value": pivotalXToken,
      },
    ];

    this.restService.SendPatchRequest(url, callback, data);
  }

  async PostProject(
    callback: any,
    code: string,
    name: string,
    description: string,
    clientId: string,
    pivotalProjectId: string,
    pivotalXToken: string
  ) {
    const url = `api/projects`;
    const d = new Date();
    d.toISOString();
    const data = {
      Code: code,
      Name: name,
      Description: description,
      ClientId: clientId,
      CreatedOn: `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`,
      pivotalProjectId: pivotalProjectId,
      pivotalXToken: pivotalXToken,
    };

    this.restService.SendPostRequest(url, callback, data);
  }
}
