import Moment from "react-moment";
import { TimeEntryFormEditModal } from "./TimeEntryFormEditModal";
import { TimeEntryDeleteButtonModal } from "./Buttons/TimeEntryDeleteButtonModal";
import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import styles from "./TimeEntryCard.module.css";
import {
  Card,
  Avatar,
  IconButton,
  Button,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";
import { timeApi } from "../../api/generated/applicationClient";

interface TimeEntryCardProps {
  entry: timeApi.TimeEntry;
  callback: () => any;
}

export const TimeEntryCard = ({ entry, callback }: TimeEntryCardProps) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const editClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowEdit(true);
  };
  const deleteCardClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowDelete(!showDelete);
    event.stopPropagation();
  };

  const editTimeEntryCallback = () => {
    setShowEdit(false);
    callback();
  };

  return (
    <>
      <TimeEntryFormEditModal
        show={showEdit}
        entry={entry}
        callback={editTimeEntryCallback}
        cancelCallback={() => setShowEdit(false)}
      />

      <TimeEntryDeleteButtonModal
        value={entry.timeEntryId}
        callback={callback}
        show={showDelete}
        callbackCloseForm={() => setShowDelete(false)}
      />
      <Card className={styles.card} key={entry.timeEntryId} onClick={editClick}>
        <Grid container>
          <Grid xs={2} md={1} item>
            <Avatar
              sx={{
                bgcolor: `rgb(160, 160, 162)`,
                color: `white`,
                margin: `10px`,
              }}
              aria-label={entry.project?.client?.name ?? ``}
            >
              {entry.project?.client?.code?.substring(0, 2) ?? ``}
            </Avatar>
          </Grid>
          <Grid xs={9} md={2} item>
            <IconButton aria-label="Time Entry Date">
              <span className={styles.timeEntryDate}>
                <Moment format="ddd MM/DD/YYYY">{entry.dateOfWork}</Moment>
              </span>
            </IconButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12} md={6} item>
            <Typography
              sx={{ margin: `15px` }}
              className={styles.timeEntryClient}
            >
              {entry.project?.client?.name ?? ``} - {`${entry.project?.name}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12} md={6} item>
            <Tooltip title={entry.descriptionOfWork}>
              <Typography
                sx={{ margin: `15px` }}
                className={styles.timeEntryDescription}
              >
                {entry.descriptionOfWork && entry.descriptionOfWork?.length > 90
                  ? `${entry.descriptionOfWork?.substring(0, 89)}...`
                  : entry.descriptionOfWork ?? ``}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid xs={9} md={2} item>
            <IconButton aria-label="settings">
              <span className={styles.timeEntryHours}>
                {entry.hoursWorked} hrs
              </span>
            </IconButton>
          </Grid>{" "}
          <Grid xs={2} md={1} item>
            <Button aria-label="outlined" test-id="deleteTimeEntry" onClick={deleteCardClick}>
              <FaTrash />
            </Button>
          </Grid>
        </Grid>
      </Card>
      {/* <CardContent className={styles.cardText}>
          <Grid container>
            <Grid xs={2} md={1} item>
              <Avatar
                sx={{ bgcolor: `cornflowerblue`, color: `white` }}
                aria-label={entry.project?.client?.name ?? ``}
              >
                {entry.project?.client?.code?.substring(0, 2) ?? ``}
              </Avatar>
            </Grid>
            <Grid xs={9} md={2} item>
              <IconButton aria-label="settings">
                <span className={styles.timeEntryDate}>
                  <Moment format="ddd MM/DD/YYYY">{entry.dateOfWork}</Moment>
                </span>
              </IconButton>
            </Grid>
            <Grid xs={9} md={6} item>
              <Typography className={styles.timeEntryClient} noWrap>
                {entry.project?.client?.name ?? ``} - {`${entry.project?.name}`}
              </Typography>
              <Tooltip title={entry.descriptionOfWork}>
                <Typography className={styles.timeEntryDescription}>
                  {entry.descriptionOfWork &&
                  entry.descriptionOfWork?.length > 90
                    ? `${entry.descriptionOfWork?.substring(0, 89)}...`
                    : entry.descriptionOfWork ?? ``}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid xs={9} md={2} item>
              {" "}
              <IconButton aria-label="settings">
                <span className={styles.timeEntryHours}>
                  {entry.hoursWorked} hrs
                </span>
              </IconButton>
            </Grid>
            <Grid xs={2} md={1} item>
              <Button aria-label="outlined" onClick={deleteCardClick}>
                <FaTrash />
              </Button>
            </Grid>
             <Grid xs={10} md={3} item>
              <span className={styles.timeSubmittedDate}>
                Created on{" "}
                <Moment format="ddd MM/DD/YYYY hh:mm A">
                  {entry.createdOn}
                </Moment>
              </span>
            </Grid> 
          </Grid>
        </CardContent> */}
    </>
  );
};
