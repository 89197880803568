import { timeApi } from "../../../api/generated/applicationClient";
import { MetricDisplay } from "./MetricDisplay";

interface ITimeEntryMetricNumberOfHoursDisplayProps {
  entries: timeApi.TimeEntry[];
}

export const TimeEntryMetricNumberOfHoursDisplay = ({
  entries,
}: ITimeEntryMetricNumberOfHoursDisplayProps) => {
  let sum = entries.reduce((t, { hoursWorked }) => t + hoursWorked, 0);

  return <MetricDisplay metricUnit="Hours" metricValue={sum.toString()} />;
};
