interface ISelectYearProps {
  year: number;
  callback: (v: string) => void;
}
export const SelectYear = ({ callback, year }: ISelectYearProps) => {
  const handleYearSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    callback(event.target.value);
  };

  return (
    <select value={year} id="year" onChange={handleYearSelect}>
      <option value="2024">2024</option>
      <option value="2023">2023</option>
      <option value="2022">2022</option>
      <option value="2021">2021</option>
      <option value="2020">2020</option>
      <option value="2019">2019</option>
      <option value="2018">2018</option>
      <option value="All">All</option>
    </select>
  );
};
