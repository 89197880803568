import { DownloadButton } from "../Shared/DownloadButton";

interface InvoiceDownloadButtonProps {
  entryId: number;
  callback: () => void;
}

export const InvoiceDownloadButton = ({
  entryId,
  callback,
}: InvoiceDownloadButtonProps) => {
  const apiData = { path: "api/invoices/download/" + entryId };

  return <DownloadButton apiData={apiData} callback={callback} />;
};
