import { useEffect, useState, useCallback } from "react";
import { TimeEntryApi } from "../../api/api-timeentries";
import { useTitle } from "../../hooks/useTitle";
import { LoadingBackDrop } from "../Shared/LoadingBackdrop";
import { Grid, Typography } from "@mui/material";
import { ApplicationPaths } from "../../api/api-authorization/ApiAuthorizationConstants";
import { useNavigate } from "react-router-dom";
import { EntryTable } from "./WeeklyEntryTable/EntryTable";
import { WeekSelect } from "./WeeklyEntryTable/WeekSelect";
import { timeApi } from "../../api/generated/applicationClient";
import { useAnalytics } from "../../hooks/useAnalytics";

export const WeeklyTime = () => {
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState<timeApi.TimeEntry[]>([]);
  const [monthSelected, setMonthSelected] = useState(`All`); // useState(getCurrentMonthPadded);
  const [yearSelected, setYearSelected] = useState(2024);
  let navigate = useNavigate();
  const [weekSelected, setWeekSelected] = useState<timeApi.WeekInfo>();
  const [dateString, setDateString] = useState("5/1/2021");

  useTitle(`Time Entry`);
  useAnalytics();

  const reloadTable = useCallback(async () => {
    setLoading(true);
    const datasource = new TimeEntryApi();
    try {
      const refreshedEntries = await datasource.GetTimeEntries(
        monthSelected,
        yearSelected
      );
      setEntries(refreshedEntries);
    }
    catch (e) {
      console.log(e);
      navigate(`${ApplicationPaths.Login}`);
    }
    finally {
      setLoading(false);
    }
  }, [monthSelected, yearSelected]);

  useEffect(() => {
    reloadTable();
  }, [yearSelected, monthSelected, reloadTable]);

  const handleTableUpdate = (e: any) => {
    console.log(e);
  }

  const handleWeekChange = (week: timeApi.WeekInfo) => {
    setWeekSelected(week);
    const dateObj = new Date(week.weekStartDate);
    const month = dateObj.getUTCMonth() + 1; // months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const newDate = `${month}/${day}/${year}`;
    setDateString(newDate);
  }

  return loading ? (
    <LoadingBackDrop />
  ) : (
    <Grid container>
      <Grid item>
        <Typography variant="h5" gutterBottom>
          Weekly hours for:
        </Typography>
        <WeekSelect
          numberOfWeeks={10}
          fromDate={0}
          callback={handleWeekChange}></WeekSelect>
      </Grid>
      <Grid item>
        <EntryTable weekStartDate={dateString} callback={handleTableUpdate} />
      </Grid>
    </Grid>
  );
};
