import { FormControl, Grid, MenuItem, InputLabel, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { TimeEntryApi } from "../../../api/api-timeentries";
import { timeApi } from "../../../api/generated/applicationClient";

interface IWeekSelectProps {
    numberOfWeeks: number;
    fromDate: number;
    callback: (week: timeApi.WeekInfo) => any;
}

export const WeekSelect = ({
    numberOfWeeks,
    fromDate,
    callback,
}: IWeekSelectProps) => {

    const [weeks, setWeeks] = useState<timeApi.WeekInfo[]>(new Array<timeApi.WeekInfo>());
    const [weekId, setWeekId] = useState<number>(1);

    useEffect(() => {
        lookupWeekData(1);
    }, []);

    const lookupWeekData = async (weekId: number) => {
        const datasource = new TimeEntryApi();
        let result: timeApi.WeekInfo[] = await datasource.getWeekInfoForPastXWeeks(numberOfWeeks, weekChanged);
        setWeeks(result);
        setWeekId(result[0].weekNumber);
        console.log(result);
    }

    const weekChanged = (week: timeApi.WeekInfo) => {
        console.log(week);
    }


    const handleChange = (e: any) => {
        console.log(e.target.value);
        setWeekId(e.target.value);
        const fWeek = [...weeks].filter(function (i) {
            return i.weekNumber === Number(e.target.value);
        })[0];
        console.log(fWeek);

        //callback(e.target.value);
        callback(fWeek);
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{ width: "400px" }}>
                <FormControl required fullWidth>
                    <InputLabel id="week-select-label">Week Selection</InputLabel>
                    <Select
                        labelId="week-select-label"
                        id="week-select"
                        label="Week"
                        onChange={(e) => handleChange(e)}
                        value={weekId}
                    >
                        {weeks != null && weeks != undefined ? weeks.map((w: timeApi.WeekInfo, index) => (
                            <MenuItem key={w.weekNumber} value={w.weekNumber}>
                                Week {w.weekNumber}: {w.weekDatesStringArray[index]}
                            </MenuItem>
                        )) : <MenuItem value={1}>No Weeks</MenuItem>}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};


