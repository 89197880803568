interface IMetricDisplayProps {
  metricUnit: string;
  metricValue: string;
}

export const MetricDisplay = ({
  metricUnit,
  metricValue,
}: IMetricDisplayProps) => {
  let content = null;
  if (metricUnit && metricValue) {
    content = metricValue + " " + metricUnit;
  } else {
    content = <h2>Something went wrong!</h2>;
  }
  return <div>{content}</div>;
};
