import { RestService } from "./rest-service";

export class InvitationApi {
  restService: RestService;
  constructor() {
    this.restService = new RestService();
  }

  async CreateInvitation(projectId: string, email: string, callback: any) {
    const url = `api/projectinvitations`;
    const data = {
      InviteeEmail: email,
      ProjectId: projectId,
    };

    this.restService.SendPostRequest(url, callback, data);
  }
}
