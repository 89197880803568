import { useState } from "react";
import authService from "../../api/api-authorization/AuthorizeService";
import axios from "axios";
import { Box, TextField } from "@mui/material";

// import { UsersSelectList } from "../Users/UsersSelectList";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
// import { timeApi } from "../../api/generated/applicationClient";
import { ModalDialog } from "../Shared/ModalDialog";
import { FloatingAddButton } from "../Shared/FloatingAddButton";

interface ClientFormEntryProps {
  callback: () => any;
}

export const ClientFormEntry = ({ callback }: ClientFormEntryProps) => {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const [invoiceManagerUserId] = useState("");

  // const selectUser = (user: timeApi.ApplicationUser) => {
  //   setInvoiceManagerUserId(user.id ?? ``);
  // };

  const saveEntry = async () => {
    const data = {
      Code: code,
      Name: name,
      Description: description,
      InvoiceManagerUserId: invoiceManagerUserId,
    };

    const [user, token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);
    axios
      .post("api/clients", data, {
        headers: {
          "Authorization": `Bearer ${token}`,
          essUsername: user.name,
        },
      })
      .then(() => {
        setCode("");
        setName("");
        setDescription("");
        setShowForm(!showForm);
        callback();
      })
      .catch((error) => {
        if (error.response?.data?.Code.length > 0) {
          setValidationMsg("Code must be 10 characters or less");
        }
      });
  };

  const validateCode = (value: string) => {
    if (value.length > 10) {
      setValidationMsg("Code must be less than 10 characters");
    } else {
      setValidationMsg("");
    }
    setCode(value);
  };

  return (
    <div>
      <FloatingAddButton onButtonClick={() => setShowForm(true)} />

      <ModalDialog
        title="Client Details"
        onClose={() => setShowForm(false)}
        show={showForm}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 2, width: "auto" },
          }}
        >
          <div className="form-group">
            <TextField
              id="code"
              name="code"
              label="Code"
              value={code}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => validateCode(e.target.value)}
            />
            {validationMsg !== "" ? (
              <div className="alert alert-danger">{validationMsg}</div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="form-group">
            <TextField
              id="name"
              name="name"
              label="Client Name"
              value={name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="description"
              name="description"
              label="Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {/* <div className="form-group">
            <label>Invoice Manager</label>
            <UsersSelectList
              userId={invoiceManagerUserId}
              callback={selectUser}
            />
          </div> */}
          <br />
          <SaveCancelButtons
            onSave={saveEntry}
            onCancel={() => setShowForm(false)}
            disableSave={false}
          />
        </Box>
      </ModalDialog>
    </div>
  );
};
