interface ISelectMonthProps {
  selectedMonth: string;
  selectedYear: number;
  callback: (v: string) => void;
}

export const SelectMonth = ({
  selectedMonth,
  selectedYear,
  callback,
}: ISelectMonthProps) => {
  return (
    <input
      type="month"
      value={`${selectedYear}-${selectedMonth}`}
      onChange={(e) => callback(e.target.value)}
    />
  );
};
