import { ClientFormEdit } from "./ClientFormEdit";
import { ClientDeleteButton } from "./ClientDeleteButton";
import Moment from "react-moment";
import { ErrorBoundary } from "../Shared/ErrorBoundary";
import { timeApi } from "../../api/generated/applicationClient";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Box,
} from "@mui/material";

interface ITimeClientValuesTableProps {
  entries: timeApi.Client[];
  callback: () => any;
}

export const ClientValuesTable = ({
  entries,
  callback,
}: ITimeClientValuesTableProps) => {
  const renderEntriesTable = (clients: timeApi.Client[]) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="Clients">
          <TableHead>
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.reverse().map((client) => (
              <TableRow key={client.clientId}>
                <TableCell>
                  <Stack>
                    <Box>
                      <strong>
                        {client.name} ({client.code})
                      </strong>
                    </Box>

                    <Box>{client.description}</Box>
                    <Box style={{ "paddingTop": "5px" }}>
                      Client Since&nbsp;
                      <Moment format="MM/DD/YYYY">{client.createDate}</Moment>
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell>
                  <ClientFormEdit entry={client} callback={callback} />
                </TableCell>
                <TableCell>
                  <ClientDeleteButton
                    entryId={client.clientId}
                    callback={callback}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  let contents = renderEntriesTable(entries);

  return (
    <ErrorBoundary>
      <div>{contents}</div>
    </ErrorBoundary>
  );
};
