import { RestService } from "./rest-service";
import { timeApi } from "./generated/applicationClient";

export class InvoiceApi {
  restService: RestService;
  apiClient: timeApi.InvoicesClient;
  constructor() {
    this.restService = new RestService();
    this.apiClient = new timeApi.InvoicesClient(
      `${window.location.protocol}//${window.location.host}`,
      this.restService.axiosInstance
    );
  }
  async GetAllInvoices() {
    await this.restService.GetAxiosInstance();
    this.apiClient = new timeApi.InvoicesClient(
      `${window.location.protocol}//${window.location.host}`,
      this.restService.axiosInstance
      );
      if (this.restService.user) {

          const entries = this.apiClient.getInvoices(this.restService.user.name);
          return entries;
      } else {
          return [];
      }
  }

  async UpdateInvoice(
    entryId: number,
    callback: any,
    clientId: number | undefined,
    invoiceDate: Date,
    dueDate: Date,
    totalAmount: number,
    descriptionOfWork: string,
    amountPaid: number
  ) {
    const url = `api/Invoices/${entryId}`;
    const data = [
      {
        "path": "/totalAmount",
        "op": "replace",
        "value": totalAmount,
      },
      {
        "path": "/amountPaid",
        "op": "replace",
        "value": amountPaid,
      },
      {
        "path": "/descriptionOfWork",
        "op": "replace",
        "value": descriptionOfWork,
      },
      {
        "path": "/clientId",
        "op": "replace",
        "value": clientId,
      },
      {
        "path": "/invoiceDate",
        "op": "replace",
        "value": invoiceDate,
      },
      {
        "path": "/dueDate",
        "op": "replace",
        "value": dueDate,
      },
    ];

    this.restService.SendPatchRequest(url, callback, data);
  }
}
