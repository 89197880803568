import { DeleteButton } from "../Shared/DeleteButton";

interface IProjectDeleteButtonProps {
  entryId: string;
  callback: () => any;
}
export const ProjectDeleteButton = ({
  entryId,
  callback,
}: IProjectDeleteButtonProps) => {
  const apiData = { path: "api/projects/" + entryId };

  return (
    <DeleteButton
      apiData={apiData}
      modalTitle="Delete Project"
      callback={callback}
    />
  );
};
