import { ErrorBoundary } from "../Shared/ErrorBoundary";

import { AgGridReact } from "ag-grid-react";
import { timeApi } from "../../api/generated/applicationClient";
import { useMemo, useState } from "react";


import { colorSchemeDark } from 'ag-grid-community';

import {
  QuickFilterModule,
  ClientSideRowModelModule,
  ModuleRegistry,
  NumberFilterModule,
  DateFilterModule,
  TextFilterModule,
  ValidationModule,
  createGrid, themeQuartz
} from "ag-grid-community";

ModuleRegistry.registerModules([ClientSideRowModelModule, NumberFilterModule,
  TextFilterModule, DateFilterModule,
  QuickFilterModule,
  ValidationModule /* Development Only */,]);

interface ITimeEntryValuesTableProps {
  entries: timeApi.TimeEntry[];
}
export const TimeEntryValuesTable = ({
  entries,
}: ITimeEntryValuesTableProps) => {
  const theme = localStorage.getItem("theme");
  const myTheme = themeQuartz.withPart(colorSchemeDark);

  const filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      const dateAsString = cellValue.getMonth() + 1 + "/" + ("0" + cellValue.getDate()).slice(-2) + "/" + cellValue.getFullYear();
      if (dateAsString == null) return -1;
      const dateParts = dateAsString.split("/");
      const cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2025,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };

  const [columnDefs]: any = useState([
    { headerName: "Id", field: "timeEntryId", hide: true },
    {
      headerName: "Date",
      field: "dateOfWork",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      cellRenderer: (data: any) => {
        const date = new Date(data.value);
        return (
          date.getMonth() + 1 + "/" + ("0" + date.getDate()).slice(-2) + "/" + date.getFullYear()
        );
      },
    },
    {
      headerName: "Client-Project",
      width: 285,
      filter: "agTextColumnFilter",
      valueGetter: (data: any) => {
        return `${data.data.project.client.code}-${data.data.project.name}`;
      },
    },
    { headerName: "Hrs", field: "hoursWorked", width: 85 },
    { headerName: "Summary", field: "descriptionOfWork", width: 350, filter: "agTextColumnFilter", },
  ]);

  // const [quickFilterText, setQuickFilterText] = useState("");


  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
    };
  }, []);

  // const onBtnExportDataAsCsv = () => {
  //   //const params = getParams();
  //   // grid.current.api.exportDataAsCsv(params);
  // };

  // const contents = renderEntriesTable(entries, columnDefs);
  const [quickFilterText, setQuickFilterText] = useState("");

  return (
    <ErrorBoundary>
      {/* <input
        type="text"
        onChange={(e) => setQuickFilterText(e.target.value)}
        placeholder="Type text to filter..."
      ></input> */}
      {/* <div>{contents}</div> */}
      <div style={{ height: "500px", width: "100%" }}>
        {/* <Button variant="outlined" onClick={() => onBtnExportDataAsCsv()}>
          Export
        </Button> */}
         <input
          type="text"
          onChange={(e) => setQuickFilterText(e.target.value)}
          placeholder="Type text to filter..."
        ></input> 
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          quickFilterText={quickFilterText}
          rowData={entries}
          theme={myTheme}
        ></AgGridReact>
      </div>
    </ErrorBoundary>
  );
};
