import { RestService } from "./rest-service";
import { timeApi } from "./generated/applicationClient";
import authService from "./api-authorization/AuthorizeService";
import axios from "axios";

export class TimeEntryApi {
  restService: RestService;
  apiClient: timeApi.TimeEntriesClient;
  constructor() {
    this.restService = new RestService();
    this.apiClient = new timeApi.TimeEntriesClient(
      `${window.location.protocol}//${window.location.host}`,
      this.restService.axiosInstance
    );
  }

  async getWeekInfoForPastXWeeks(weeks: number, callback: any) {
    this.restService = new RestService();
    await this.restService.GetAxiosInstance();
    this.apiClient = new timeApi.TimeEntriesClient(
      `${window.location.protocol}//${window.location.host}`,
      this.restService.axiosInstance
    );
    const entries: timeApi.WeekInfo[] =
      await this.apiClient.getWeekInfoForPastXWeeks(weeks, new Date(Date.now()));
    return entries;
  }

  async GetTimeEntries(month: string, year: number) {
    this.restService = new RestService();
    await this.restService.GetAxiosInstance();
    this.apiClient = new timeApi.TimeEntriesClient(
      `${window.location.protocol}//${window.location.host}`,
      this.restService.axiosInstance
    );
    const entries: timeApi.TimeEntry[] =
      await this.apiClient.getTimeEntriesByMonth(
        month,
        year.toString(),
        undefined,
        undefined,
        "desc",
        this.restService.user.name,
        undefined,
        undefined,
        undefined
      );
    return entries;
  }

  async GetTimeEntriesPerDay(month: string, year: string, callback: any) {
    await this.restService.GetAxiosInstance();

    const entries: timeApi.TimeEntry[] =
      await this.apiClient.timeEntriesByDayOfWeek(
        month,
        year.toString(),
        null,
        null
      );
    return entries;
  }

  async GetTimeEntry(entryId: string, callback: any) {
    await this.restService.GetAxiosInstance();
    const entry = await this.apiClient.getTimeEntry(Number(entryId));
    return entry;
  }

  async PostTimeEntry(
    dateOfWork: Date,
    billable: boolean,
    hoursWorked: number,
    descriptionOfWork: string,
    projectId: string,
    callback: any
  ) {
    const url = `api/TimeEntries`;
    const data = {
      DateOfWork: dateOfWork.toISOString(),
      Billable: Boolean(billable),
      HoursWorked: hoursWorked,
      DescriptionOfWork: descriptionOfWork,
      ProjectId: projectId,
    };

    this.restService.SendPostRequest(url, callback, data);
  }

  async UpdateTimeEntry(
    entryId: number,
    callback: any,
    dateOfWork: Date,
    hoursWorked: number,
    descriptionOfWork: string,
    projectId: Number | undefined
  ) {
    const url = `api/TimeEntries/${entryId}`;
    const data = [
      {
        "path": "/dateOfWork",
        "op": "replace",
        "value": dateOfWork,
      },
      {
        "path": "/hoursWorked",
        "op": "replace",
        "value": hoursWorked,
      },
      {
        "path": "/descriptionOfWork",
        "op": "replace",
        "value": descriptionOfWork,
      },
      {
        "path": "/projectId",
        "op": "replace",
        "value": projectId,
      },
    ];

    this.restService.SendPatchRequest(url, callback, data);
  }

  async DeleteTimeEntry(
    id: string,
    callback: any
  ) {

    const apiData = { path: "api/TimeEntries/" + id };
    const token = await authService.getAccessToken();
    axios
      .delete(apiData.path, {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then(() => {
        callback();
      });
    callback();
  }
}
