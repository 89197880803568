import { useState } from "react";
import { ClientsSelectList } from "../Clients/ClientsSelectList";
import { ClientContext } from "../contexts/ClientProvider";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
import { Box, FormControl, TextField } from "@mui/material";
import { timeApi } from "../../api/generated/applicationClient";
import { InvoiceApi } from "../../api/api-invoices";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

interface InvoiceFormEditProps {
  entry: timeApi.Invoice;
  callback: () => void;
  onCancel: () => any;
}

export const InvoiceFormEdit = (props: InvoiceFormEditProps) => {
  const [descriptionOfWork, setDescriptionOfWork] = useState(
    props.entry?.descriptionOfWork
  );
  const [dueDate, setDueDate] = useState(new Date(props.entry.dueDate));
  const [invoiceDate, setInvoiceDate] = useState(props.entry?.invoiceDate);
  const [totalAmount, setTotalAmount] = useState(props.entry?.totalAmount);
  const [amountPaid, setAmountPaid] = useState(props.entry?.amountPaid);
  const [client, setClient] = useState(props.entry?.client);

  const editEntry = async () => {
    const invoiceApi = new InvoiceApi();
    invoiceApi.UpdateInvoice(
      props.entry.invoiceId,
      props.callback,
      client?.clientId,
      invoiceDate,
      dueDate,
      totalAmount,
      descriptionOfWork ?? ``,
      amountPaid
    );
  };

  const invoiceDateSelected = (date: Date) => {
    setInvoiceDate(date);
    const d2 = moment(date).add(30, 'd').toDate();
    setDueDate(d2);
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 2, width: "auto" },
      }}
    >
      <div>
        <ClientContext.Consumer>
          {(clientContext) => (
            <ClientsSelectList
              client={client}
              allClients={clientContext.clientData}
              callback={(c: timeApi.Client) => setClient(c)}
            />
          )}
        </ClientContext.Consumer>
      </div>
      <div>
        <FormControl required>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Invoice Date"
              value={invoiceDate.toString()}
              onChange={(
                value: String | null,
                keyboardInputValue?: string | undefined
              ) => {
                invoiceDateSelected(moment(value?.toString()).toDate());
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
      <div>
        <FormControl required>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Due Date"
              value={dueDate.toString()}
              onChange={(value: String | null) => {
                setDueDate(moment(value?.toString()).toDate());
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
      <div>
        <TextField
          required
          id="totalAmount"
          label="Total Amount"
          type="number"
          value={totalAmount}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setTotalAmount(Number(e.target.value))}
        />
      </div>
      <div>
        <TextField
          id="amountPaid"
          label="Amount Paid"
          type="number"
          value={amountPaid}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setAmountPaid(Number(e.target.value))}
        />
      </div>
      <div>
        <TextField
          id="descriptionOfWork"
          label="Description"
          multiline
          rows={4}
          value={descriptionOfWork}
          onChange={(e) => setDescriptionOfWork(e.target.value)}
        />
      </div>
      <br />
      <SaveCancelButtons
        onSave={editEntry}
        onCancel={() => props.onCancel()}
        disableSave={false}
      />
    </Box>
  );
};
