import { useState, useEffect } from "react";
import { ProjectSelectList } from "../Projects/ProjectSelectList";
import { CircularProgress, Stack } from "@mui/material";
import StoriesList from "../Pivotal/StoriesList";
import { TimeEntryApi } from "../../api/api-timeentries";
import { Box, FormControl, TextField, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
import { timeApi } from "../../api/generated/applicationClient";
import { ModalDialog } from "../Shared/ModalDialog";
import { ChatGptApi } from "../../api/api-chatgpt";

interface ITimeEntryFormEditModalProps {
  show: boolean;
  entry: timeApi.TimeEntry;
  callback: () => void;
  cancelCallback: () => any;
}

export const TimeEntryFormEditModal = (props: ITimeEntryFormEditModalProps) => {
  const [dateOfWork, setDateOfWork] = useState(props.entry.dateOfWork);
  const [hoursWorked, setHoursWorked] = useState(props.entry.hoursWorked);
  const [descriptionOfWork, setDescriptionOfWork] = useState(
    props.entry.descriptionOfWork ?? ``
  );
  const [projectId, setProjectId] = useState<number>(props.entry.projectId);
  const [hoursValid, setHoursValid] = useState(false);
  const [hoursInvalidMsg, setHoursInvalidMsg] = useState(``);

  // hours validation
  useEffect(() => {
    const check = hoursWorked < 100;
    setHoursValid(check);
    if (check === false) {
      setHoursInvalidMsg(`Entries must be less than 100 hours`);
    } else {
      setHoursInvalidMsg(``);
    }
  }, [hoursWorked]);

  const handleClose = () => {
    props.cancelCallback();
  };

  const getChatGptDescription = async () => {
    const gptClient = new ChatGptApi();
    const recommended = await gptClient.GetPromptResponse(
      `Make this sound professional: ${descriptionOfWork}`
    );
    setDescriptionOfWork(recommended.toString());
  };

  const editEntry = async () => {
    const teClient = new TimeEntryApi();
    teClient
      .UpdateTimeEntry(
        props.entry.timeEntryId,
        props.callback,
        dateOfWork,
        hoursWorked,
        descriptionOfWork,
        projectId
      )
      .then(() => {
        handleClose();
        // props.callback();
      });
  };

  const handleKeypress = (e: React.KeyboardEvent) => {
    //it triggers by pressing the enter key
    if (e.key === `Enter`) {
      editEntry();
    }
  };

  return (
    <Box>
      <ModalDialog
        title="Project Details"
        onClose={() => props.cancelCallback()}
        show={props.show}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 2, width: "auto" },
          }}
        >
          <div style={{ width: "230px" }}>
            {props.entry.project ? (
              <ProjectSelectList
                project={props.entry.project}
                callback={(e) => setProjectId(e.projectId)}
              />
            ) : (
              <CircularProgress color="inherit" />
            )}
          </div>
          <div>
            <FormControl required>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  value={dateOfWork.toString()}
                  onChange={(
                    value: String | null,
                    keyboardInputValue?: string | undefined
                  ) => {
                    setDateOfWork(moment(value?.toString()).toDate());
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div>
            <TextField
              required
              id="hoursWorked"
              label="Hours Worked"
              type="number"
              /* pattern="[0=9]*" */
              value={hoursWorked}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setHoursWorked(Number(e.target.value))}
              onKeyDown={(e) => handleKeypress(e)}
              error={!hoursValid}
              helperText={hoursInvalidMsg}
            />
          </div>
          <div>
            <TextField
              id="description"
              label="Description"
              multiline
              rows={4}
              fullWidth
              value={descriptionOfWork}
              onChange={(e) => setDescriptionOfWork(e.target.value)}
            />
          </div>
          <br />
          <Button onClick={getChatGptDescription}>
            Get ChatGPT Description
          </Button>
        </Box>
        <SaveCancelButtons
          onSave={editEntry}
          onCancel={() => handleClose()}
          disableSave={false}
        />
        <br />
        <Stack>
          <h3>
            Pivotal Stories from {dateOfWork.toLocaleDateString()} +/- 2 days
          </h3>

          <StoriesList date={dateOfWork} projectid={projectId} />
        </Stack>
      </ModalDialog>
    </Box>
  );
};
