import { FaLock } from "react-icons/fa";
import { timeApi } from "../../api/generated/applicationClient";
import { useUserLockoutStatus } from "./useUserLockoutStatus ";

interface ILockOutDisplayProps {
  user: timeApi.ApplicationUser;
}

export const LockOutDisplay = ({ user }: ILockOutDisplayProps) => {
  const [isLocked] = useUserLockoutStatus(user);
  const label = isLocked ? (
    <span style={{ color: "red" }}>
      <FaLock />
      &nbsp; Account Locked
    </span>
  ) : (
    <span style={{ color: "green" }}>Active</span>
  );
  return label;
};
