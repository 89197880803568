import { useState, useContext } from "react";
import { FormControl, MenuItem, InputLabel, Select } from "@mui/material";

import { ProjectContext } from "../contexts/ProjectProvider";
import { timeApi } from "../../api/generated/applicationClient";

interface IProjectSelectListProps {
  project: timeApi.Project;
  callback: (project: timeApi.Project) => any;
}
export const ProjectSelectList = ({
  project,
  callback,
}: IProjectSelectListProps) => {
  const projectCtx = useContext(ProjectContext);
  const allProjects = projectCtx.projectData;
  const [projectId, setProjectId] = useState<number>(project.projectId);

  const handleChange = (e: any) => {
    const fProject = [...allProjects].filter(function (i) {
      return i.projectId === Number(e.target.value);
    })[0];

    setProjectId(fProject.projectId);
    callback(fProject);
  };

  return (
    <div>
      <FormControl required fullWidth>
        <InputLabel id="project-select-label">Project</InputLabel>
        <Select
          labelId="project-select-label"
          id="project-select"
          label="Project"
          onChange={(e) => handleChange(e)}
          value={projectId}
        >
          {allProjects.map((p: timeApi.Project) => (
            <MenuItem key={p.projectId} value={p.projectId}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
