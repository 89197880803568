import { useAnalytics } from "../../hooks/useAnalytics";
export const About = () => {
  useAnalytics();
  return (
    <div>
      <h1>About</h1>
      <p>Welcome to our single-page application, built with:</p>
      <ul>
        <li>
          <a href="https://get.asp.net/">ASP.NET Core</a> and{" "}
          <a href="https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx">
            C#
          </a>{" "}
          for cross-platform server-side code
        </li>
        <li>
          <a href="https://facebook.github.io/react/">ReactJS</a> for
          client-side code
        </li>
        <li>
          <a href="http://getbootstrap.com/">Bootstrap</a> for layout and
          styling
        </li>
        <li>
          <a href="https://github.com/truedge">Git Repository</a>{" "}
        </li>
        <li>
          <a href="https://docs.microsoft.com/en-us/azure/devops/pipelines/get-started/what-is-azure-pipelines">
            Azure Pipelines
          </a>{" "}
          for build and release automation
        </li>
        <li>
          <a href="https://docs.microsoft.com/en-us/azure/app-service/deploy-staging-slots">
            Azure Deployment Slots
          </a>{" "}
          to run multiple envionments with agility
        </li>
        <li>
          <a href="https://azure.microsoft.com/en-us/services/sql-database/campaign/">
            Azure MSSQL Instance
          </a>{" "}
          data storage
        </li>
        <li>
          <a href="https://create-react-app.dev/docs/adding-a-sass-stylesheet/">
            SASS
          </a>{" "}
          css preprocessing
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://localhost:3000/d/Sn17QTKMk/ess-time-entry-admin?orgId=2"
          >
            Grafana admin-Fgh
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://localhost:5001/swagger/index.html"
          >
            Swagger API Documentation
          </a>
        </li>
      </ul>
      <p>Other Features:</p>
      <ul>
        <li>
          <strong>Development server integration</strong>. In development mode,
          the development server from <code>create-react-app</code> runs in the
          background automatically, so your client-side resources are
          dynamically built on demand and the page refreshes when you modify any
          file.
        </li>
        <li>
          <strong>Efficient production builds</strong>. In production mode,
          development-time features are disabled, and your{" "}
          <code>dotnet publish</code> configuration produces minified,
          efficiently bundled JavaScript files.
        </li>
      </ul>
      <p>
        The <code>ClientApp</code> subdirectory is a standard React application
        based on the <code>create-react-app</code> template. If you open a
        command prompt in that directory, you can run <code>npm</code> commands
        such as <code>npm test</code> or <code>npm install</code>.
      </p>
    </div>
  );
};
