import { useState } from "react";
import { Button, Grid } from "@mui/material";
import { InvitationApi } from "../../api/api-invitations";

export const UserInviteByEmail = (props) => {
  const [email, setEmail] = useState("");

  const createInvitation = async () => {
    const _client = new InvitationApi();
    _client
      .createInvitation(props.projectId, email, props.callback)
      .then(() => {
        setEmail("");
      });
  };

  const updateFormValue = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <br />
      <h2>Add Member</h2>
      <Grid container>
        <Grid item>
          <div className="form-group">
            <label>Email</label>
            <input
              className="form-control"
              name="email"
              value={email}
              onChange={updateFormValue}
            />
          </div>
        </Grid>
        <Grid item>
          <br />
          <Button variant="contained" onClick={createInvitation}>
            <i className="fa-plus" />
            Add
          </Button>
        </Grid>
      </Grid>

      <p aria-live="polite">
        Email: <strong>{email}</strong>
      </p>
    </div>
  );
};
