import { useEffect, useState } from "react";
import { useNavigate, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";
import { LoadingBackDrop } from "../../components/Shared/LoadingBackdrop";

const AuthorizeRoute = (props) => {
  const [ready, setReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [_subscription] = useState(
    authService.subscribe(() => authenticationChanged())
  );

  useEffect(() => {
    populateAuthenticationState();
    return () => authService.unsubscribe(_subscription);
  }, [_subscription]);

  const navigate = useNavigate();

  // redirect url
  var link = document.createElement("a");
  link.href = props.path;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURIComponent(returnUrl)}`;

  const authenticationChanged = async () => {
    setReady(false);
    setAuthenticated(false);
    await populateAuthenticationState();
  };

  const populateAuthenticationState = async () => {
    const authenticatedNewValue = await authService.isAuthenticated();
    setAuthenticated(authenticatedNewValue);
    setReady(true);
  };

  // redirect to login page
  useEffect(() => {
    if (ready && !authenticated && window.location.pathname === props.path) {
      navigate(redirectUrl);
    }
  }, [ready, authenticated, navigate, redirectUrl, props.path]);

  return !ready ? (
    <LoadingBackDrop message="Getting ready" />
  ) : (
    <Routes>
      <Route element={props.element} path={props.path} />
    </Routes>
  );
};
export default AuthorizeRoute;
