import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CalendarIcon from "@mui/icons-material/CalendarToday";

export const NavOptions = () => {
  const menuItems = [
    { label: "Time Entry", icon: <AccessTimeIcon />, to: "/TimeEntry" },
    { label: "Invoices", icon: <CurrencyExchangeIcon />, to: "/Invoices" },
    { label: "Clients", icon: <AddBusinessIcon />, to: "/Clients" },
    { label: "Projects", icon: <AccountTreeIcon />, to: "/Projects" },
    { label: "Reports", icon: <AssessmentIcon />, to: "/Reports" },
    { label: "Weekly Time Entry", icon: <CalendarIcon />, to: "/WeeklyTime" },
  ];

  return (
    <List>
      {menuItems.map((menuItem, index) => (
        <ListItem
          key={menuItem.label}
          component={Link}
          to={menuItem.to}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon color="action">{menuItem.icon}</ListItemIcon>
            <ListItemText secondary={menuItem.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
