import { Card, CardContent, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { FaHammer } from "react-icons/fa";
import { EnvironmentStampApi } from "../../api/api-envstamp";
import { EnvironmentStampDetails } from "../Types/EnvironmentStamp";

export const EnvironmentStamp = () => {
  const [environmentName, setEnvronmentName] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [buildNumber, setBuildNumber] = useState("");
  const datasource = new EnvironmentStampApi();

  useEffect(() => {
    datasource.GetEnvironmentStamp((res: EnvironmentStampDetails) => {
      setEnvronmentName(res.environmentName);
      setBackgroundColor(res.backgroundColor);
      setBuildNumber(res.buildNumber);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card>
        <CardContent>
          <Box aria-label={environmentName}>
            <FaHammer color={backgroundColor} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <>Build: {buildNumber}</>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
