import { useEffect, useState } from "react";
import { PivotalApi } from "../../api/api-pivotal";
import { Box, Card, CardContent, CircularProgress, Stack } from "@mui/material";
import styles from "./StoriesList.module.css";

interface StoriesListProps {
  date: Date;
  projectid: number;
}

const StoriesList = ({ date, projectid }: StoriesListProps) => {
  const [storiesElem, setStoriesElem] = useState(
    <Stack>
      <span> Searching Pivotal Tracker</span>
      <CircularProgress color="inherit" />
    </Stack>
  );

  useEffect(() => {
    const datasource = new PivotalApi();

    datasource.GetStoriesByDateRange(date, projectid, (res: any) => {
      const storiesElement =
        "code" in res && res.code === "unfound_resource" ? (
          <h4>No Stories</h4>
        ) : (
          res.map((x: any) => (
            <a
              key={x.guid}
              href={x.primary_resources[0].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card key={x.guid}>
                <CardContent>
                  <span className={styles.storyTitle}>
                    {x.primary_resources[0].name}
                  </span>
                  <br />
                  <span className={styles.storyEventMsg}></span>
                  {x.message}
                </CardContent>
              </Card>
            </a>
          ))
        );
      setStoriesElem(<Box>{storiesElement}</Box>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Stack>{storiesElem}</Stack>;
};

export default StoriesList;
