import { ReactNode } from "react";

// data contexts
import { ProjectProvider } from "../contexts/ProjectProvider";
import { ClientProvider } from "../contexts/ClientProvider";
import { InvoiceProvider } from "../contexts/InvoiceProvider";
import { ThemeProvider } from "../contexts/ThemeProvider";
import { UserProvider } from "../contexts/UserProvider";

interface IAppContextProps {
  children: ReactNode;
}
const AppContext = ({ children }: IAppContextProps) => {
  return (
    <ThemeProvider>
      <ProjectProvider>
        <ClientProvider>
          <InvoiceProvider>
            <UserProvider>{children}</UserProvider>
          </InvoiceProvider>
        </ClientProvider>
      </ProjectProvider>
    </ThemeProvider>
  );
};

export default AppContext;
