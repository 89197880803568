import { useContext } from "react";

import { InvoiceFormEntry } from "./InvoiceFormEntry";
import { InvoiceValuesTable } from "./InvoiceValuesTable";

import { InvoiceContext } from "../contexts/InvoiceProvider";
import { useTitle } from "../../hooks/useTitle";
import { Grid } from "@mui/material";
import { useAnalytics } from "../../hooks/useAnalytics";

export const Invoices = () => {
  useTitle(`Invoices`);
  useAnalytics();
  const invoiceCtx = useContext(InvoiceContext);

  return (
    <Grid container>
      <Grid item xs={6}>
        <InvoiceFormEntry callback={invoiceCtx.updateInvoiceContext} />
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={12}>
        <InvoiceValuesTable
          entries={invoiceCtx.invoiceData}
          callback={invoiceCtx.updateInvoiceContext}
        />
      </Grid>
    </Grid>
  );
};
