import { Component, PureComponent } from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    let date = new Date(payload.value);
    let retDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#00000"
          transform="rotate(-90)"
        >
          {retDate}
        </text>
      </g>
    );
  }
}

export class TimeEntryBarChart extends Component {
  static displayName = TimeEntryBarChart.name;

  constructor(props) {
    super(props);
    this.state = {
      sortedEntries: this.props.entries.sort(this.compare),
    };
  }

  formatXAxis(tickItem) {
    // Format X axis date
    let date = new Date(tickItem);
    let retDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return retDate;
  }

  // sorting by date asc
  compare(a, b) {
    if (a.dateOfWork < b.dateOfWork) {
      return -1;
    }
    if (a.dateOfWork > b.dateOfWork) {
      return 1;
    }
    return 0;
  }

  render() {
    return (
      <div>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            margin={{ top: 5, right: 30, left: 20, bottom: 50 }}
            data={this.props.entries.sort(this.compare)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="dateOfWork"
              tickFormatter={this.formatXAxis}
              tick={<CustomizedAxisTick />}
            />
            <YAxis />
            <Tooltip dataKey="descriptionOfWork" />
            <Bar dataKey="hoursWorked" fill="#0ac775" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
