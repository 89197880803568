import { useContext } from "react";
import { ClientFormEntry } from "./ClientFormEntry";
import { ClientValuesTable } from "./ClientValuesTable";
import { ClientContext } from "../contexts/ClientProvider";
import { useTitle } from "../../hooks/useTitle";
import { Box, Grid } from "@mui/material";
import { useAnalytics } from "../../hooks/useAnalytics";

export const Clients = () => {
  useTitle(`Clients`);
  useAnalytics();

  const clientCtx = useContext(ClientContext);

  return (
    <Box>
      <div className="no-print"></div>
      <Grid container>
        <Grid item xs={6}>
          <ClientFormEntry callback={clientCtx.updateClientContext} />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <ClientValuesTable
            entries={clientCtx.clientData}
            callback={clientCtx.updateClientContext}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
