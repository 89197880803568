import React, { useEffect, useMemo, useState } from "react";
import { ClientApi } from "../../api/api-clients";
import { timeApi } from "../../api/generated/applicationClient";
import { IProviderProps } from "../Types/IProviderProps";

// define the context model to be provided to consumers
export const ClientContext = React.createContext({
  clientData: new Array<timeApi.Client>(),
  updateClientContext: () => {},
});

// define the provider that will populate date to be provided
export const ClientProvider = ({ children }: IProviderProps) => {
  const [clientData, setClientData] = useState<Array<timeApi.Client>>([]);
  const datasource = useMemo(() => new ClientApi(), []);

  // Get data on App load
  useEffect(() => {
    datasource.GetAllClients((res: any) => setClientData(res));
    console.log("running request GetAllClients");
  }, [datasource]);

  const clientContext = {
    clientData: clientData,
    updateClientContext: () => {
      // Function to pass with data incase clientData needs updating
      datasource.GetAllClients((res: any) => setClientData(res));
      console.log("running request updateClientContext");
    },
  };

  return (
    <ClientContext.Provider value={clientContext}>
      {children}
    </ClientContext.Provider>
  );
};
