import { useContext } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";

import { FiClock } from "react-icons/fi";
import { Link } from "react-router-dom";
import { LoginMenu } from "../../api/api-authorization/LoginMenu2";
import { NavMenuDesktop } from "./NavMenuDesktop";
import { NavMenuMobile } from "./NavMenuMobile";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { themeContext } from "../contexts/ThemeProvider";
import { NavOptions } from "./NavOptions";
import { EnvironmentStamp } from "../Shared/EnvironmentStamp";
import { IsMobile } from "../../common/MobileCheck";

import styles from "./NavMenu.module.css";
interface NavMenuProps {
  mainContent?: React.ReactNode;
}

export const NavMenu = (props: NavMenuProps) => {
  const drawerWidth = 240;

  const themectx = useContext(themeContext);
  let themeConfig;

  // @ts-ignore
  if (themectx.themeData === `dark`) {
    themeConfig = createTheme({
      palette: {
        // us
        mode: `dark`,
      },
    });
  } else {
    themeConfig = createTheme({
      palette: {
        mode: `light`,
      },
    });
  }

  const drawer = (
    <div>
      <Divider />
      <br />
      <Link className={styles.navbarBrand} to="/">
        <Box>
          <FiClock size={30} />
        </Box>
        <Box> timeandinvoice.com</Box>
      </Link>

      <Divider />
      <NavOptions />
      <Divider />
      <LoginMenu></LoginMenu>

      <Box style={{ bottom: "100px" }}>
        <EnvironmentStamp />
      </Box>
    </div>
  );

  return (
    <ThemeProvider theme={themeConfig}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="Navigation"
        >
          {IsMobile() ? (
            <NavMenuMobile drawer={drawer} />
          ) : (
            <NavMenuDesktop drawer={drawer} />
          )}
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          {IsMobile() ? (
            <>
              <br />
              <br />
            </>
          ) : (
            <></>
          )}
          {props.mainContent}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
