import { Dialog, DialogContent, DialogTitle } from "@mui/material";

interface IModalDialogProps {
  title: string;
  show: boolean;
  children: React.ReactNode;
  onClose: () => any;
}

export const ModalDialog = (props: IModalDialogProps) => {
  return (
    <Dialog
      open={props.show}
      transitionDuration={{
        enter: 1100,
        exit: 1000,
      }}
      onClose={props.onClose}
    >
      <DialogTitle>{props.title}</DialogTitle>

      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
};
