import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Button, Box, TextField } from "@mui/material";

import { ClientApi } from "../../api/api-clients";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
import { timeApi } from "../../api/generated/applicationClient";
import { ModalDialog } from "../Shared/ModalDialog";

interface IClientFormEdit {
  entry: timeApi.Client;
  callback: () => void;
}

export const ClientFormEdit = ({ entry, callback }: IClientFormEdit) => {
  const [code, setCode] = useState(entry.code);
  const [name, setName] = useState(entry.name);
  const [description, setDescription] = useState(entry.description);
  const [showForm, setShowForm] = useState(false);

  const editEntry = async () => {
    const cClient = new ClientApi();

    cClient
      .UpdateClient(
        entry.clientId,
        code ?? ``,
        name ?? ``,
        description ?? ``,
        callback
      )
      .then(() => {
        setShowForm(false);
      });
  };

  return (
    <div>
      <Button variant="contained" onClick={() => setShowForm(true)}>
        <FiEdit />
      </Button>

      <ModalDialog
        title="Client Details"
        onClose={() => setShowForm(false)}
        show={showForm}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 2, width: "auto" },
          }}
        >
          <div>
            <TextField
              id="code"
              name="code"
              label="Company Code"
              value={code}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setCode(e.currentTarget.value)}
            />
          </div>
          <div>
            <TextField
              id="name"
              name="name"
              label="Company Name"
              value={name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </div>
          <div>
            <TextField
              id="description"
              name="description"
              label="Description"
              value={description}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setDescription(e.currentTarget.value)}
            />
          </div>
          <br />
          <SaveCancelButtons
            onSave={() => editEntry()}
            onCancel={() => setShowForm(false)}
            disableSave={false}
          />
        </Box>
      </ModalDialog>
    </div>
  );
};
