import { UserFormEntry } from "./UserFormEntry";
import { UserValuesTable } from "./UserValuesTable";
import ExportCsvButton from "../Shared/ExportCsvButton";
import { ErrorBoundary } from "../Shared/ErrorBoundary";
import { useEffect, useContext } from "react";
import { useTitle } from "../../hooks/useTitle";
import { Grid } from "@mui/material";
import { UserContext } from "../contexts/UserProvider";
import { useAnalytics } from "../../hooks/useAnalytics";

export const Users = () => {
  useTitle(`Users`);
  useAnalytics();
  const d = new Date();
  const exportFileName = `Users-${d.getMonth()}-${d.getFullYear()}.csv`;
  const users = useContext(UserContext);
  useEffect(() => {
    users.updateUserContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ErrorBoundary localErrorMsg="Users.. something went wrong">
        <Grid container>
          <Grid item xs={6}>
            <UserFormEntry callback={users.updateUserContext} />
          </Grid>
          <Grid item xs={6}>
            <ExportCsvButton
              linkDisplayText="Export to CSV"
              data={users.userData}
              filename={exportFileName}
            />
          </Grid>
          <Grid item xs={12}>
            <UserValuesTable
              entries={users.userData}
              callbackRowDeleted={users.updateUserContext}
              callbackRowUpdated={users.updateUserContext}
            />
          </Grid>
        </Grid>
      </ErrorBoundary>
    </div>
  );
};
