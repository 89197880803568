import React, { useEffect, useMemo, useState } from "react";
import { ProjectApi } from "../../api/api-projects";
import { timeApi } from "../../api/generated/applicationClient";
import { IProviderProps } from "../Types/IProviderProps";

export const ProjectContext = React.createContext({
  projectData: new Array<timeApi.Project>(),
  updateProjectContext: () => {},
});

export const ProjectProvider = ({ children }: IProviderProps) => {
  const [projectData, setProjectData] = useState<timeApi.Project[]>([]);
  const datasource = useMemo(() => new ProjectApi(), []);

  // Get data on App load
  useEffect(() => {
    datasource.GetAllProjects((res: timeApi.Project[]) => setProjectData(res));
    console.log("running request GetAllProjects");
  }, [datasource]);

  const projectContext = {
    projectData: projectData,
    updateProjectContext: () => {
      // Function to pass with data incase projectData needs updating
      datasource.GetAllProjects((res: timeApi.Project[]) =>
        setProjectData(res)
      );
      console.log("running request updateProjectContext");
    },
  };
  return (
    <ProjectContext.Provider value={projectContext}>
      {children}
    </ProjectContext.Provider>
  );
};
