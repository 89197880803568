import { useState } from "react";
import authService from "../../api/api-authorization/AuthorizeService";
import axios from "axios";
import { Button, Box } from "@mui/material";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
import { ModalDialog } from "../Shared/ModalDialog";

export const UserFormEntry = (props) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showForm, setShowForm] = useState(false);

  const saveEntry = async () => {
    const d = new Date();
    d.toISOString();

    const data = {
      Email: emailAddress,
      PhoneNumber: phoneNumber,
      CreatedOn: `${d.getMonth()}/${d.getDate()}/${d.getFullYear()}`,
    };

    const [user, token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);
    axios
      .post("api/applicationuser", data, {
        headers: !token
          ? {}
          : {
              "Authorization": `Bearer ${token}`,
              essUsername: user.name,
            },
      })
      .then(() => {
        setEmailAddress("");
        setPhoneNumber("");
        setShowForm(!showForm);
        props.callback();
      });
  };

  const updateEmail = (event) => {
    setEmailAddress(event.target.value);
  };

  const updatePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <div>
      <Button variant="contained" onClick={setShowForm}>
        <i className="fa-plus" />
        New User
      </Button>

      <ModalDialog
        title="User Details"
        onClose={() => setShowForm(false)}
        show={showForm}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 2, width: "auto" },
          }}
        >
          <h1>User Details</h1>
          <div className="form-group">
            <label>Email</label>
            <input
              className="form-control"
              name="email"
              value={emailAddress}
              onChange={updateEmail}
            />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              className="form-control"
              name="phoneNumber"
              value={phoneNumber}
              onChange={updatePhoneNumber}
            />
          </div>
          <SaveCancelButtons
            onSave={saveEntry}
            onCancel={() => setShowForm(false)}
            disableSave={false}
          />
        </Box>
      </ModalDialog>
    </div>
  );
};
