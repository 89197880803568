import Drawer from "@mui/material/Drawer";
import { ReactNode } from "react";

interface INavMenuDesktopProps {
  drawer: ReactNode;
}

export const NavMenuDesktop = (props: INavMenuDesktopProps) => {
  const drawerWidth = 240;

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
        },
      }}
    >
      {props.drawer}
    </Drawer>
  );
};
