import { useState } from "react";
import authService from "../../api/api-authorization/AuthorizeService";
import axios from "axios";
import { Button } from "@mui/material";

import { FiEdit } from "react-icons/fi";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
import { Box } from "@mui/material";
import { ModalDialog } from "../Shared/ModalDialog";
export const UserFormEdit = (props) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showForm, setShowForm] = useState(false);

  const toggleShow = () => {
    setShowForm(!showForm);
  };

  const handleClose = () => {
    toggleShow();
  };

  const updateEmail = (event) => {
    setEmailAddress(event.target.value);
  };

  const updatePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const editEntry = async () => {
    const d = new Date();
    d.toISOString();

    const entryId = props.entry.id;

    const data = [
      {
        "path": "/email",
        "op": "replace",
        "value": emailAddress,
      },
      {
        "path": "/phoneNumber",
        "op": "replace",
        "value": phoneNumber,
      },
    ];

    const [user, token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);
    axios
      .patch("api/applicationuser/" + entryId, data, {
        headers: {
          "Authorization": `Bearer ${token}`,
          essUsername: user.name,
        },
      })
      .then(() => {
        handleClose();
        props.callback();
      });
  };

  return (
    <div>
      <Button variant="contained" onClick={setShowForm}>
        <FiEdit />
      </Button>
      <Box>
        <ModalDialog
          title="User Details"
          onClose={() => setShowForm(false)}
          show={showForm}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 2, width: "auto" },
            }}
          ></Box>

          <h1>UserFormEdit</h1>
          <div className="form-group">
            <label>User Name</label>
            <input
              className="form-control"
              name="email"
              value={emailAddress}
              onChange={updateEmail}
            />
          </div>
          <div className="form-group">
            <label>User Name</label>
            <input
              className="form-control"
              name="phoneNumber"
              value={phoneNumber}
              onChange={updatePhoneNumber}
            />
          </div>
          <SaveCancelButtons
            onSave={editEntry}
            onCancel={() => handleClose()}
            disableSave={false}
          />
        </ModalDialog>
      </Box>
    </div>
  );
};
