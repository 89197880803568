import { ReactNode } from "react";
import { FaCoffee, FaLightbulb } from "react-icons/fa";
import { ErrorBoundary as ErrorBoundaryFramework } from "react-error-boundary";

interface IErrorBoundaryProps {
  localErrorMsg?: string;
  children: ReactNode;
}
export const ErrorBoundary = ({
  localErrorMsg,
  children,
}: IErrorBoundaryProps) => {
  function ErrorFallback() {
    return (
      <div className="alert alert-info" style={{ textAlign: "center" }}>
        <FaLightbulb /> This function is temporarily unavailable. We&apos;re
        working on it. <FaCoffee />
        <br />
        <span>{localErrorMsg ?? `********* no further info **********`}</span>
      </div>
    );
  }

  return (
    <ErrorBoundaryFramework FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundaryFramework>
  );
};
