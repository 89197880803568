import React, { useEffect, useMemo, useState } from "react";

import { UserApi } from "../../api/api-users";
import { timeApi } from "../../api/generated/applicationClient";
import { IProviderProps } from "../Types/IProviderProps";

export const UserContext = React.createContext({
  userData: new Array<timeApi.ApplicationUser>(),
  updateUserContext: () => {},
});

export const UserProvider = ({ children }: IProviderProps) => {
  const [userData, setUserData] = useState([]);
  const datasource = useMemo(() => new UserApi(), []);

  // Get data on App load
  useEffect(() => {
    datasource.GetAllUsers((res: any) => setUserData(res));
    console.log("running request GetAllUsers");
  }, [datasource]);

  const userContext = {
    userData: userData,
    updateUserContext: () => {
      // Function to pass with data incase userData needs updating
      datasource.GetAllUsers((res: any) => setUserData(res));
      console.log("running request updateUserContext");
    },
  };

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};
