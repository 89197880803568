import { UserFormEdit } from "./UserFormEdit";
import { UserDeleteButton } from "./UserDeleteButton";
import { ErrorBoundary } from "../Shared/ErrorBoundary";
import { CircularProgress } from "@mui/material";
import { LockOutDisplay } from "./LockoutDisplay";
import { LockUnlockButton } from "./LockUnlockButton";
import Moment from "react-moment";
import { timeApi } from "../../api/generated/applicationClient";

interface IUserValuesTableProps {
  entries: timeApi.ApplicationUser[];
  callbackRowUpdated: () => any;
  callbackRowDeleted: () => any;
}
export const UserValuesTable = ({
  entries,
  callbackRowUpdated,
  callbackRowDeleted,
}: IUserValuesTableProps) => {
  const renderEntriesTable = (entries: timeApi.ApplicationUser[]) => {
    if (entries !== undefined && entries !== null) {
      return (
        <div>
          <table className="table" aria-labelledby="tabelLabel">
            <thead>
              <tr>
                <th>User</th>
                <th>Create Date</th>
                <th>Status</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {entries.reverse().map((entry: timeApi.ApplicationUser) => (
                <tr key={entry.id}>
                  <td>{entry.userName}</td>
                  <td>
                    <Moment format="MM/DD/YYYY">{entry.createdOn}</Moment>
                  </td>
                  <td>
                    <LockOutDisplay
                      user={entry}
                      /* callback={callbackRowUpdated} */
                    />
                  </td>
                  <td>
                    <LockUnlockButton
                      user={entry}
                      callback={callbackRowUpdated}
                    />
                  </td>
                  <td className="no-print">
                    <UserFormEdit entry={entry} callback={callbackRowUpdated} />
                  </td>
                  <td className="no-print">
                    <UserDeleteButton
                      userId={entry.id ?? ``}
                      callback={callbackRowDeleted}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <CircularProgress color="inherit" />;
    }
  };

  let contents = renderEntriesTable(entries);

  return (
    <ErrorBoundary>
      <div>{contents}</div>
    </ErrorBoundary>
  );
};
