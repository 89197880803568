import authService from "../../api/api-authorization/AuthorizeService";
import axios from "axios";
import { FaDownload } from "react-icons/fa";
import { Button } from "@mui/material";

interface DownloadButtonProps {
  callback: () => any;
  apiData: any;
}

const base64ToArrayBuffer = (base64: string) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

const saveByteArray = (reportName: string, byte: Uint8Array) => {
  var blob = new Blob([byte], { type: "application/pdf" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
};

export const DownloadButton = ({ apiData, callback }: DownloadButtonProps) => {
  const downloadEntry = async (event: React.MouseEvent<HTMLElement>) => {
    const token = await authService.getAccessToken();
    axios
      .get(apiData.path, {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((response) => {
        var sampleArr = base64ToArrayBuffer(response.data.fileContents);
        saveByteArray(response.data.fileDownloadName, sampleArr);
        console.log(`Entry downloaded via ${apiData.path}`);

        event.stopPropagation();
        callback();
      });
  };

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          downloadEntry(e);
        }}
        title="Download"
        variant="contained"
        aria-label="Download"
      >
        <FaDownload />
      </Button>
    </>
  );
};
