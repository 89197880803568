import React, { Component } from "react";
import {
  RadarChart,
  Radar,
  ResponsiveContainer,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

export class TimeEntryRadarChart extends Component {
  static displayName = TimeEntryRadarChart.name;

  constructor(props) {
    super(props);

    this.state = {
      maxRadius: 0,
    };
  }

  componentDidMount() {
    this.setMaxRadius();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entries !== this.props.entries) {
      this.setMaxRadius();
    }
  }

  setMaxRadius = () => {
    let max = 0;
    max = this.props.entries.reduce(
      (t, { hoursWorked }) => (t > { hoursWorked } ? t : { hoursWorked }),
      0
    );

    this.setState({
      maxRadius: max,
    });
  };

  renderLabel = (entry) => `${entry.dayOfWeek} ${entry.count}`;

  render() {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <RadarChart
          outerRadius={90}
          width={50}
          height={50}
          data={this.props.entries}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="dayOfWeek" />
          <PolarRadiusAxis angle={45} domain={[0, this.state.maxRadius]} />
          <Radar
            name={this.renderLabel}
            dataKey="count"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    );
  }
}
