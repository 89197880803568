import styles from "./TimeEntryMetricLabels.module.css";
import { TimeEntryMetricNumberOfEntriesDisplay } from "./TimeEntryMetricNumberOfEntriesDisplay";
import { TimeEntryMetricNumberOfHoursDisplay } from "./TimeEntryMetricNumberOfHoursDisplay";
import { TimeEntryMetricNumberOfDaysDisplay } from "./TimeEntryMetricNumberOfDaysDisplay";
import { Card, CardContent, Grid } from "@mui/material";
import { timeApi } from "../../../api/generated/applicationClient";
import { ErrorBoundary } from "../../Shared/ErrorBoundary";

interface TimeEntryMetricLabelsProps {
  entries: timeApi.TimeEntry[];
}

const TimeEntryMetricLabels = ({ entries }: TimeEntryMetricLabelsProps) => (
  <Grid container>
    <Grid item xs={3}>
      <Card className={styles.card}>
        <CardContent>
          <ErrorBoundary>
            <span style={{ textAlign: "center" }}>
              <TimeEntryMetricNumberOfEntriesDisplay entries={entries} />
            </span>
          </ErrorBoundary>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={3}>
      <Card className={styles.card}>
        <CardContent>
          <ErrorBoundary>
            <div style={{ textAlign: "center" }}>
              <TimeEntryMetricNumberOfHoursDisplay entries={entries} />
            </div>
          </ErrorBoundary>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={3}>
      <Card className={styles.card}>
        <CardContent>
          <ErrorBoundary>
            <div style={{ textAlign: "center" }}>
              <TimeEntryMetricNumberOfDaysDisplay entries={entries} />
            </div>
          </ErrorBoundary>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);
export default TimeEntryMetricLabels;
