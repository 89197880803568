import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import authService from "./AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { FiLogIn, FiLogOut, FiUser } from "react-icons/fi";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FaRegistered } from "react-icons/fa";

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);
    this.setState({
      isAuthenticated,
      userName: user && user.name,
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = {
        pathname: `${ApplicationPaths.LogOut}`,
        state: { local: true },
      };
      return this.authenticatedView(userName, profilePath, logoutPath);
    }
  }

  authenticatedView(userName, profilePath, logoutPath) {
    const menuItems = [
      { label: `Profile`, icon: <FiUser />, to: profilePath },
      { label: "Logout", icon: <FiLogOut />, to: logoutPath },
    ];

    return (
      <List>
        {menuItems.map((menuItem) => (
          <ListItem
            key={menuItem.label}
            component={Link}
            to={menuItem.to}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={menuItem.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  }

  anonymousView(registerPath, loginPath) {
    const menuItems = [
      { label: "Register", icon: <FaRegistered />, to: registerPath },
      { label: "Login", icon: <FiLogIn />, to: loginPath },
    ];

    return (
      <Fragment>
        <List>
          {menuItems.map((menuItem) => (
            <ListItem
              key={menuItem.label}
              component={Link}
              to={menuItem.to}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon color="action">{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Fragment>
    );
  }
}
