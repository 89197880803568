import { Button } from "@mui/material";
import authService from "../../api/api-authorization/AuthorizeService";
import axios from "axios";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useUserLockoutStatus } from "./useUserLockoutStatus ";
import { timeApi } from "../../api/generated/applicationClient";

interface ILockUnlockButtonProps {
  user: timeApi.ApplicationUser;
  callback: any;
}

export const LockUnlockButton = ({
  user,
  callback,
}: ILockUnlockButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked] = useUserLockoutStatus(user);

  const onUnlock = async () => {
    const apiData = { path: `api/applicationuser/unlock/${user.id}` };
    sendLockRequest(apiData);
  };
  const onLock = async () => {
    const apiData = { path: `api/applicationuser/lockout/${user.id}` };
    sendLockRequest(apiData);
  };

  const sendLockRequest = async (apiData: any) => {
    setIsLoading(true);
    const token = await authService.getAccessToken();
    axios
      .post(apiData.path, null, {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then(() => {
        console.log(`Account unlocked via ${apiData.path}`);
        callback();
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const spinner = isLoading ? <CircularProgress color="inherit" /> : "";
  const button = isLocked ? (
    <Button variant="outlined" title="Unlock User" onClick={onUnlock}>
      Unlock {spinner}
    </Button>
  ) : (
    <Button variant="contained" title="Lock User" onClick={onLock}>
      Lock {spinner}
    </Button>
  );
  return button;
};
