// @ts-check
import { IsMobile } from "../../common/MobileCheck";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useTitle } from "../../hooks/useTitle";
import { Box } from "@mui/material";
import ReactTypingEffect from "react-typing-effect";

const Home = () => {
  useTitle(`timeandinvoice.com`);
  useAnalytics();
  return (
    <div>
      {IsMobile() ? (
        <h5>You are on Mobile Version of the App</h5>
      ) : (
        <h5>You are on the Desktop version</h5>
      )}
      <h4>Simple time tracking. </h4>
      <ReactTypingEffect
        speed={100}
        eraseDelay={1000000}
        text="Easily track your time by client and projects. Turbocharge your entries with ChatGPT recommendations. Create printable invoice
      documents."
      />
      <div className="container">
        <Box>
          <ul>
            <li>Register</li>
            <li>Add a Client</li>
            <li>Add a Project</li>
            <li>Enter Time</li>
            <li>Review Time</li>
            <li>Create a Printable Invoice</li>
          </ul>
        </Box>
      </div>
      <br />
      <br />
    </div>
  );
};
export default Home;
