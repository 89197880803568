import { useState, useEffect } from "react";
import { ProjectApi } from "../../api/api-projects";
import {
  Box,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  Grid
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
import { TimeEntryApi } from "../../api/api-timeentries";
import { ModalDialog } from "../Shared/ModalDialog";
import { timeApi } from "../../api/generated/applicationClient";
import { FloatingAddButton } from "../Shared/FloatingAddButton";
import { ChatGptApi } from "../../api/api-chatgpt";
import { WandMenu } from "./WandMenu";

interface TimeEntryFormProps {
  callback: () => any;
}
export const TimeEntryForm = ({ callback }: TimeEntryFormProps) => {
  const [dateOfWork, setDateOfWork] = useState(new Date());
  const [hoursWorked, setHoursWorked] = useState<number>();
  const [descriptionOfWork, setDescriptionOfWork] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [projectSelectOptions, setProjectSelectOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [projectId, setProjectId] = useState<string>(``);
  const [billable, setBillable] = useState(false);
  const [hoursValid, setHoursValid] = useState(false);
  const [hoursInvalidMsg, setHoursInvalidMsg] = useState(``);
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const datasource = new ProjectApi();
    datasource.GetAllProjects((res: timeApi.Project[]) => {
      const result = res.map((projectData: timeApi.Project) => ({
        value: projectData.projectId,
        label: projectData.name ?? ``,
      }));
      setProjectSelectOptions(result);
    });
  }, []);

  const makeThisSoundMore = (adjective: string) => {
    if(adjective==""){
      return
    }
      
    const prompt = `Make this sound more ${adjective}: ${descriptionOfWork}`;
    getChatGptDescription(prompt);
  };

  const getChatGptDescription = async (prompt: string) => {
    const gptClient = new ChatGptApi();
    const recommended = await gptClient.GetPromptResponse(
      prompt
    );
    setDescriptionOfWork(recommended.toString());
  };

  const handleKeypress = (e: React.KeyboardEvent) => {
    //it triggers by pressing the enter key
    if (e.key === `Enter`) {
      saveEntry();
    }
  };

  const saveEntry = async () => {
    const teClient = new TimeEntryApi();
    teClient
      .PostTimeEntry(
        dateOfWork,
        billable,
        hoursWorked ?? 0,
        descriptionOfWork,
        projectId,
        callback
      )
      .then(() => {
        setDateOfWork(new Date());
        setBillable(false);
        setHoursWorked(0);
        setDescriptionOfWork("");
        setShowForm(!showForm);
      });
  };

  useEffect(() => {
    const vhoursWorked = hoursWorked ?? 0;
    const check = vhoursWorked < 100;
    setHoursValid(check);
    if (check === false) {
      setHoursInvalidMsg(`Entries must be less than 100 hours`);
    } else {
      setHoursInvalidMsg(``);
    }
  }, [hoursWorked]);

  useEffect(() => {
    setFormValid(hoursValid && projectId !== ``);
  }, [hoursValid, projectId]);

  function enhanceDescription(adjectiveSelected: string) {
    makeThisSoundMore(adjectiveSelected);
  }

  return (
    <>
      <br />
      <FloatingAddButton onButtonClick={() => setShowForm(true)} />
      <Box>
        <ModalDialog
          title="Project Details"
          onClose={() => setShowForm(false)}
          show={showForm}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 2, width: "auto" },
            }}
          >
            <div style={{ width: "230px" }}>
              <FormControl required fullWidth>
                <InputLabel id="project-select-label">Project</InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  label="Project"
                  onChange={(e) => {
                    e && setProjectId(e.target.value);
                  }}
                  value={projectId}
                >
                  {projectSelectOptions.map((project) => (
                    <MenuItem key={project.value} value={project.value}>
                      {project.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={dateOfWork.toString()}
                    onChange={(
                      value: String | null,
                      keyboardInputValue?: string | undefined
                    ) => {
                      setDateOfWork(moment(value?.toString()).toDate());
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div>
              <TextField
                required
                id="outlined-number"
                label="Hours Worked"
                type="number"
                value={hoursWorked}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setHoursWorked(Number(e.target.value))}
                onKeyDown={(e) => handleKeypress(e)}
                error={!hoursValid}
                helperText={hoursInvalidMsg}
              />
            </div>
            <div>
              <Grid container>

                <Grid item>
                  <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    fullWidth
                    value={descriptionOfWork}
                    onChange={(e) => setDescriptionOfWork(e.target.value)}
                  /> </Grid> <Grid item> <WandMenu callback={enhanceDescription} /></Grid>  </Grid>

            </div>
                 <br />
          </Box>
          <SaveCancelButtons
            onSave={saveEntry}
            onCancel={() => setShowForm(false)}
            disableSave={!formValid}
          />
          <br />
          <br />
        </ModalDialog>
      </Box>
    </>
  );
};
