import { useState } from "react";
import authService from "../../api/api-authorization/AuthorizeService";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import { Button, Grid } from "@mui/material";
import { ModalDialog } from "./ModalDialog";

interface DeleteButtonProps {
  callback: () => any;
  apiData: any;
  modalTitle: string;
}
export const DeleteButton = ({
  apiData,
  modalTitle,
  callback,
}: DeleteButtonProps) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const deleteEntry = async (event: React.MouseEvent<HTMLElement>) => {
    const token = await authService.getAccessToken();
    axios
      .delete(apiData.path, {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then(() => {
        console.log(`Entry deleted via ${apiData.path}`);
        event.stopPropagation();
        callback();
      });
    setShowDeleteConfirm(false);
  };

  const deleteClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowDeleteConfirm(true);
    // event.stopPropagation();
  };

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          deleteClick(e);
        }}
        title="Delete Time Entry"
        variant="contained"
        aria-label="Delete Time Entry"
        id="btn-delete"
      >
        <FaTrash />
      </Button>

      <ModalDialog
        title={modalTitle}
        onClose={() => setShowDeleteConfirm(false)}
        show={showDeleteConfirm}
      >
        <p>Are you sure you want to delete?</p>
        <Grid container>
          <Grid item xs lg={4}>
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                deleteEntry(e);
              }}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs lg={2}>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteConfirm(false);
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </ModalDialog>
    </>
  );
};
