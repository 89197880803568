import Box from "@mui/material/Box";
import { FC } from "react";
import styles from "./EntryColumn.module.css";
import { FormControl, TextField } from "@mui/material";

export interface EntryColumnProps {
  hours: number | null;
  callback: (hoursWorked: number) => any;
}

const EntryColumn: FC<EntryColumnProps> = (props) => (
  <Box className={styles.EntryColumn}>
    <FormControl required fullWidth>
      <TextField
              id="hours"
              name="hours"
              label=""
              value={props.hours ?? 0}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                let retEntry = props.hours;
                retEntry = Number(e.target.value);
                props.callback(retEntry);
              }}
            />
    </FormControl>
  </Box>
);

export default EntryColumn;
