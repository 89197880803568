import { timeApi } from "../../../api/generated/applicationClient";
import { MetricDisplay } from "./MetricDisplay";

export const TimeEntryMetricNumberOfDaysDisplay = (props: {
  entries: timeApi.TimeEntry[];
}) => {
  const onlyUnique = (value: any, index: any, self: string | any[]) => {
    return self.indexOf(value) === index;
  };

  let distinctDates = [
    ...new Set(props.entries.map((x: timeApi.TimeEntry) => x.dateOfWork)),
  ];

  let sum = distinctDates.filter(onlyUnique).length;
  return (
    <MetricDisplay
      metricUnit="Days"
      metricValue={sum.toString()}
    ></MetricDisplay>
  );
};
