import { useState } from "react";
import { ClientsSelectList } from "../Clients/ClientsSelectList";
import { ClientContext } from "../contexts/ClientProvider";
import { ProjectApi } from "../../api/api-projects";
import { Box, TextField } from "@mui/material";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
import { timeApi } from "../../api/generated/applicationClient";
import { ModalDialog } from "../Shared/ModalDialog";
import { FloatingAddButton } from "../Shared/FloatingAddButton";

interface IProjectFormEntryProps {
  callback: () => any;
}
export const ProjectFormEntry = ({ callback }: IProjectFormEntryProps) => {
  const [code, setCode] = useState(``);
  const [name, setName] = useState(``);
  const [description, setDescription] = useState(``);
  const [client, setClient] = useState<timeApi.Client | null>(null);
  const [clientId, setClientId] = useState(``);
  const [showForm, setShowForm] = useState(false);
  const [pivotalProjectId, setPivotalProjectId] = useState(``);
  const [pivotalXToken, setPivotalXToken] = useState(``);

  const selectClient = (client: timeApi.Client) => {
    setClientId(client.clientId.toString());
    setClient(client);
  };

  const saveEntry = async () => {
    const pClient = new ProjectApi();
    pClient
      .PostProject(
        callback,
        code,
        name,
        description,
        clientId,
        pivotalProjectId,
        pivotalXToken
      )
      .then(() => {
        setCode(``);
        setName(``);
        setDescription(``);
        setShowForm(false);
      });
  };

  return (
    <div>
      <FloatingAddButton onButtonClick={() => setShowForm(true)} />
      <ModalDialog
        title="Project Details"
        onClose={() => setShowForm(false)}
        show={showForm}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 2, width: "auto" },
          }}
        >
          <div>
            <ClientContext.Consumer>
              {(clientContext) => (
                <ClientsSelectList
                  client={client}
                  callback={(e) => selectClient(e)}
                  allClients={clientContext.clientData}
                />
              )}
            </ClientContext.Consumer>
          </div>
          <div>
            <TextField
              id="code"
              name="code"
              label="Project Code"
              value={code}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="name"
              name="name"
              label="Project Name"
              value={name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="description"
              name="description"
              label="Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="pivotalProjectId"
              name="pivotalProjectId"
              label="Pivotal Project Id"
              value={pivotalProjectId}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setPivotalProjectId(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="pivotalXToken"
              label="Pivotal X-Token"
              name="pivotalXToken"
              value={pivotalXToken}
              onChange={(e) => setPivotalXToken(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <br />
          <SaveCancelButtons
            onSave={() => saveEntry()}
            onCancel={() => setShowForm(false)}
            disableSave={false}
          />
        </Box>
      </ModalDialog>
    </div>
  );
};
