import { Component } from "react";
import { Button } from "@mui/material";
import { ProjectSelectList } from "../../Projects/ProjectSelectList";
import EntryColumn from "./EntryColumn/EntryColumn";
import DeleteIcon from "@mui/icons-material/Delete";

export class EntryRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.entry?.project,
      sundayHours: this.props.entry?.sundayHours,
      sundayComments: "",
      sundayDate: "",
      mondayHours: this.props.entry?.mondayHours,
      mondayComments: "",
      mondayDate: "",
      tuesdayHours: this.props.entry?.tuesdayHours,
      tuesdayComments: "",
      tuesdayDate: "",
      wednesdayHours: this.props.entry?.wednesdayHours,
      wednesdayComments: "",
      wednesdayDate: "",
      thursdayHours: this.props.entry?.thursdayHours,
      thursdayComments: "",
      thursdayDate: "",
      fridayHours: this.props.entry?.fridayHours,
      fridayComments: "",
      fridayDate: "",
      saturdayHours: this.props.entry?.saturdayHours,
      saturdayComments: "",
      saturdayDate: "",
    };
  }

  selectProject = (project) => {
    this.setState({
      projectId: project.projectId,
      projectValue: project.code,
      projectLabel: project.name,
      loading: false,
      project: project,
    });
  };

  updateFormValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <tr>
        <td>
          <div className="form-group" style={{ width: "250px" }}>
            <ProjectSelectList
              project={this.state.project}
              callback={this.selectProject}
            />
          </div>
        </td>
        <td>
          <div className="form-group">
            <EntryColumn
              date={this.state.sundayDate}
              hours={this.state.sundayHours}
              comments={this.state.sundayComments}
              callback={this.updateFormValue}
            ></EntryColumn>
          </div>
        </td>
        <td>
          <div className="form-group">
            <EntryColumn
              date={this.state.mondayDate}
              hours={this.state.mondayHours}
              comments={this.state.mondayComments}
              callback={this.updateFormValue}
            ></EntryColumn>
          </div>
        </td>
        <td>
          <div className="form-group">
            <EntryColumn
              name="tuesdayHours"
              date={this.state.tuesdayDate}
              hours={this.state.tuesdayHours}
              comments={this.state.tuesdayComments}
              callback={this.updateFormValue}></EntryColumn>
          </div>
        </td>
        <td>
          <div className="form-group">
            <EntryColumn
              name="wednesdayHours"
              date={this.state.wednesdayDate}
              hours={this.state.wednesdayHours}
              comments={this.state.wednesdayComments}
              callback={this.updateFormValue}></EntryColumn>
          </div>
        </td>
        <td>
          <div className="form-group">
            <EntryColumn
              name="thursdayHours"
              date={this.state.thursdayDate}
              hours={this.state.thursdayHours}
              comments={this.state.thursdayComments}
              callback={this.updateFormValue}></EntryColumn>
          </div>
        </td>
        <td>
          <div className="form-group">
            <EntryColumn
              name="fridayHours"
              date={this.state.fridayDate}
              hours={this.state.fridayHours}
              comments={this.state.fridayComments}
              callback={this.updateFormValue}></EntryColumn>
          </div>
        </td>
        <td>
          <div className="form-group">
            <EntryColumn
              name="saturdayHours"
              date={this.state.saturdayDate}
              hours={this.state.saturdayHours}
              comments={this.state.saturdayComments}
              callback={this.updateFormValue}></EntryColumn>
          </div>
        </td>
        <td>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            size="small"
            onClick={() => this.props.onDelete(this.props.index)}
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  }
}
