import { useEffect, useState, useCallback } from "react";
import { TimeEntryValuesCardView } from "./TimeEntryValuesCardView";
import { TimeEntryForm } from "./TimeEntryForm";
import TimeEntryMetricLabels from "./Metrics/TimeEntryMetricLabels";
import { getCurrentMonthPadded } from "../../common/DateHelper";
import { TimeEntryApi } from "../../api/api-timeentries";
import { useTitle } from "../../hooks/useTitle";
import { TimeEntryFilters } from "./TimeEntryFilters";
import { LoadingBackDrop } from "../Shared/LoadingBackdrop";
import { timeApi } from "../../api/generated/applicationClient";
import { useAnalytics } from "../../hooks/useAnalytics";
import { Grid } from "@mui/material";
import { TimeEntryValuesTable } from "./TimeEntryValuesTable";
import { ApplicationPaths } from "../../api/api-authorization/ApiAuthorizationConstants";
import { useNavigate } from "react-router-dom";

export const TimeEntryDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState<timeApi.TimeEntry[]>([]);
  const [monthSelected, setMonthSelected] = useState(`All`); // useState(getCurrentMonthPadded);
  const [yearSelected, setYearSelected] = useState(2024);
  const [showCardView, setShowCardView] = useState(true);
  const [filterBy, setFilterBy] = useState(`year`);
  let navigate = useNavigate();

  useTitle(`Time Entry`);
  useAnalytics();

  const handleFilterByChange = (val: string) => {
    console.log(val);
    if (val === "month") {
      setFilterBy("month");
      setMonthSelected(getCurrentMonthPadded());
      setYearSelected(new Date().getFullYear());
    }
    if (val === "year") {
      setFilterBy("year");
      setMonthSelected("All");
    }
    if (val === "all") {
      setFilterBy("all");
    }
  };

  const reloadTable = useCallback(async () => {
    setLoading(true);
    const datasource = new TimeEntryApi();
    try{
      const refreshedEntries = await datasource.GetTimeEntries(
        monthSelected,
        yearSelected
      );
      setEntries(refreshedEntries);
    }
    catch(e){
      console.log(e);
      navigate(`${ApplicationPaths.Login}`);
    }
    finally{
      setLoading(false);
    }
  }, [monthSelected, yearSelected]);

  useEffect(() => {
    reloadTable();
  }, [yearSelected, monthSelected, reloadTable]);

  const onMonthChange = (newMonthSelected: string) => {
    setMonthSelected(newMonthSelected);
  };

  const onYearChange = (newYearSelected: number) => {
    setYearSelected(newYearSelected);
  };

  const onViewChange = (viewName: string) => {
    const showCardView = viewName === `card` ? true : false;
    setShowCardView(showCardView);
  };

  return loading ? (
    <LoadingBackDrop />
  ) : (
    <Grid container>
      <Grid item>
        <TimeEntryForm callback={reloadTable} />
        <TimeEntryMetricLabels entries={entries} />
        <br />
      </Grid>
      <Grid xs={12} item>
        <TimeEntryFilters
          month={monthSelected}
          year={yearSelected}
          onMonthChange={onMonthChange}
          onYearChange={onYearChange}
          onViewChange={onViewChange}
          filterBy={filterBy}
          onFilterByChange={handleFilterByChange}
          viewListOrCard={showCardView ? `card` : `list`}
        />
      </Grid>
      <Grid xs={12} item>
        {showCardView ? (
          <TimeEntryValuesCardView entries={entries} callback={reloadTable} />
        ) : (
          <TimeEntryValuesTable entries={entries} />
        )}
      </Grid>
    </Grid>
  );
};
