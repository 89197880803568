import { DeleteButton } from "../Shared/DeleteButton";

interface IClientDeleteButtonProps {
  entryId: number;
  callback: () => void;
}
export const ClientDeleteButton = ({
  entryId,
  callback,
}: IClientDeleteButtonProps) => {
  const apiData = { path: "api/clients/" + entryId };

  return (
    <DeleteButton
      apiData={apiData}
      modalTitle="Delete Client"
      callback={callback}
    />
  );
};
