import { RestService } from "./rest-service";
import { timeApi } from "./generated/applicationClient";

export class ChatGptApi {
  async GetPromptResponse(prompt: string) {
    const restService = new RestService();
    await restService.GetAxiosInstance();
    const apiClient = new timeApi.ChatGptClient(
      `${window.location.protocol}//${window.location.host}`,
      restService.axiosInstance
    );
    return await apiClient.get(prompt); // .then(callback);
  }
}
//updated
