import Drawer from "@mui/material/Drawer";
import { ReactNode, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, Grid } from "@mui/material";

interface INavMenuMobileProps {
  drawer: ReactNode;
}

export const NavMenuMobile = (props: INavMenuMobileProps) => {
  const drawerWidth = `60%`;

  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Drawer
        onClick={() => setIsOpen(false)}
        variant="temporary"
        open={isOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {props.drawer}
      </Drawer>
      {!isOpen ? (
        <Drawer variant="permanent" anchor="top">
          <Grid container>
            <Grid item xs={2}>
              <Button onClick={() => setIsOpen(true)}>
                <MenuIcon fontSize="large" />
                &nbsp;Menu
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      ) : (
        <></>
      )}
    </>
  );
};
