import React, { useEffect, useState } from "react";

// define the context model to be provided to consumers
export const themeContext = React.createContext({
  themeContext: "",
  updateThemeContext: () => {},
});

// define the provider that will populate date to be provided
export const ThemeProvider = ({ children }) => {
  const [themeData, setThemeData] = useState(null);

  // Get data on App load
  useEffect(() => {
    // for css dark mode toggle store
    const defaultDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    const theme = defaultDark ? "dark" : "light";
    setThemeData(theme);
    console.log("running request getThemeContext");
  }, []);

  // Function to pass with data incase clientData needs updating
  const updateThemeContext = () => {
    //setThemeData(theme);
    setThemeData(localStorage.getItem("theme"));
    console.log("running request updateThemeContext");
  };

  const { Provider } = themeContext;

  return (
    <Provider value={{ themeData, updateThemeContext }}>{children}</Provider>
  );
};
