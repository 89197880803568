import { RestService } from "./rest-service";
import { timeApi } from "./generated/applicationClient";
import moment from "moment";

export class PivotalApi {
  restService: RestService;
  apiClient: timeApi.PivotalClient;
  constructor() {
    this.restService = new RestService();
    this.apiClient = new timeApi.PivotalClient(
      `${window.location.protocol}//${window.location.host}`,
      this.restService.axiosInstance
    );
  }
  async GetStoriesByDateRange(
    date: Date,
    projectid: number,
    callback: (res: any) => any
  ) {
    await this.restService.GetAxiosInstance();
    let filterStartDate = moment(date, "MM-DD-YYYY")
      .add(-1, "days")
      .format("MM-DD-YYYY");
    let filterEndDate = moment(date, "MM-DD-YYYY")
      .add(1, "days")
      .format("MM-DD-YYYY");
    const url = `api/storiesbydate?startDate=${filterStartDate}&endDate=${filterEndDate}&projectId=${projectid}`;
    const restService = new RestService();
    restService.SendRequest(url, callback);
  }
}
