import { useContext, useEffect } from "react";
import { ProjectFormEntry } from "./ProjectFormEntry";
import { ProjectValuesTable } from "./ProjectValuesTable";
import { ErrorBoundary } from "../Shared/ErrorBoundary";
import { ProjectContext } from "../contexts/ProjectProvider";
import { useTitle } from "../../hooks/useTitle";
import Grid from "@mui/material/Grid";
import { useAnalytics } from "../../hooks/useAnalytics";

export const Projects = () => {
  useTitle(`Projects`);
  useAnalytics();

  const projectCtx = useContext(ProjectContext);
  useEffect(() => {
    projectCtx.updateProjectContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ErrorBoundary localErrorMsg="Projects.. something went wrong">
        <Grid container>
          <Grid item xs={12}>
            <ProjectFormEntry callback={projectCtx.updateProjectContext} />
          </Grid>
          <Grid item xs={12}>
            <ProjectValuesTable
              entries={projectCtx.projectData}
              callback={projectCtx.updateProjectContext}
            />
          </Grid>
        </Grid>
      </ErrorBoundary>
    </div>
  );
};
