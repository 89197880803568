import { RestService } from "./rest-service";

export class ClientApi {
  restService: RestService;
  constructor() {
    this.restService = new RestService();
  }

  // Get a list of all clients.
  async GetAllClients(callback: any) {
    const url = `api/clients`;
    this.restService.SendRequest(url, callback);
  }

  // Get details for a specific client.
  async GetClient(clientId: number, callback: any) {
    const url = `api/clients/${clientId}`;
    this.restService.SendRequest(url, callback);
  }

  // Create a new client.
  async CreateClient(
    code: string,
    name: string,
    description: string,
    callback: any
  ) {
    const url = `api/clients`;

    // Truncate the description if it's too long.
    description =
      description.length > 500 ? description.substring(0, 499) : description;

    const data = {
      "code": code,
      "name": name,
      "description": description,
    };

    this.restService.SendPostRequest(url, callback, data);
  }

  // Update an existing client.
  async UpdateClient(
    clientId: number,
    code: string,
    name: string,
    description: string,
    callback: any
  ) {
    const url = `api/clients/${clientId}`;

    // Truncate the description if it's too long.
    description =
      description.length > 500 ? description.substring(0, 499) : description;

    const data = [
      {
        "path": "/clientId",
        "op": "replace",
        "value": clientId,
      },
      {
        "path": "/code",
        "op": "replace",
        "value": code,
      },
      {
        "path": "/name",
        "op": "replace",
        "value": name,
      },
      {
        "path": "/description",
        "op": "replace",
        "value": description,
      },
    ];

    this.restService.SendPatchRequest(url, callback, data);
  }
}
