import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { ClientsSelectList } from "../Clients/ClientsSelectList";
import { UserInviteByEmail } from "../Users/UserInviteByEmail";
import { CircularProgress } from "@mui/material";
import { ClientContext } from "../contexts/ClientProvider";
import { Button, Box, Grid, TextField } from "@mui/material";
import { ProjectApi } from "../../api/api-projects";
import { SaveCancelButtons } from "../Shared/SaveCancelButtons";
import { timeApi } from "../../api/generated/applicationClient";
import { ModalDialog } from "../Shared/ModalDialog";

interface IProjectFormEditProps {
  entry: timeApi.Project;
  callback: () => any;
}
export const ProjectFormEdit = ({ entry, callback }: IProjectFormEditProps) => {
  const [projectId] = useState(entry.projectId);
  const [clientId, setClientId] = useState(entry.clientId);
  const [code, setCode] = useState(entry.code);
  const [name, setName] = useState(entry.name);
  const [description, setDescription] = useState(entry.description);
  const [showForm, setShowForm] = useState(false);
  const [client, setClient] = useState(entry.client);
  const [pivotalProjectId, setPivotalProjectId] = useState(
    entry.pivotalProjectId
  );
  const [pivotalXToken, setPivotalXToken] = useState(entry.pivotalXToken);
  const [clientSelectListElem, setClientSelectListElem] = useState(
    <CircularProgress color="inherit" />
  );

  const setShow = () => {
    setShowForm(!showForm);
    setClientSelectListElem(
      <ClientContext.Consumer>
        {(clientContext) => (
          <ClientsSelectList
            client={client}
            allClients={clientContext.clientData}
            callback={(e) => selectClient(e)}
          />
        )}
      </ClientContext.Consumer>
    );
  };

  const membershipUpdated = () => {
    /*
    this.setState((prevState) => ({
      showForm: !prevState.showForm,
    }));
    */
  };

  const selectClient = (client: timeApi.Client) => {
    setClient(client);
    setClientId(client.clientId);
  };

  const editEntry = async () => {
    const pClient = new ProjectApi();
    pClient
      .UpdateProject(
        projectId,
        callback,
        code ?? ``,
        name ?? ``,
        description ?? ``,
        clientId,
        pivotalProjectId ?? ``,
        pivotalXToken ?? ``
      )
      .then(() => {
        setShowForm(false);
      });
  };

  return (
    <div>
      <Button variant="contained" onClick={() => setShow()}>
        <FiEdit />
      </Button>

      <ModalDialog
        title="Project Details"
        onClose={() => setShowForm(false)}
        show={showForm}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 2, width: "auto" },
          }}
        >
          <div>{clientSelectListElem}</div>
          <div>
            <TextField
              id="code"
              name="code"
              label="Project Code"
              value={code}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="name"
              name="name"
              label="Project Name"
              value={name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="description"
              name="description"
              label="Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="pivotalProjectId"
              name="pivotalProjectId"
              label="Pivotal Project Id"
              value={pivotalProjectId}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setPivotalProjectId(e.target.value)}
            />
          </div>
          <div>
            <TextField
              id="pivotalXToken"
              label="Pivotal X-Token"
              name="pivotalXToken"
              value={pivotalXToken}
              onChange={(e) => setPivotalXToken(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <br />
          <SaveCancelButtons
            onSave={() => editEntry()}
            onCancel={() => setShowForm(false)}
            disableSave={false}
          />
          <Grid>
            <Grid item xs={12}>
              <UserInviteByEmail
                projectId={projectId}
                callback={membershipUpdated}
              />
            </Grid>
          </Grid>
        </Box>
      </ModalDialog>
    </div>
  );
};
