import { InvoiceFormEdit } from "./InvoiceFormEdit";
import { InvoiceDeleteButton } from "./InvoiceDeleteButton";
import Moment from "react-moment";
import { ErrorBoundary } from "../Shared/ErrorBoundary";
import { Box, Stack } from "@mui/material";
import { TableCell, TableRow } from "@mui/material";

import { timeApi } from "../../api/generated/applicationClient";
import { useState } from "react";
import { ModalDialog } from "../Shared/ModalDialog";
import { InvoiceDownloadButton } from "./InvoiceDownloadButton";

interface InvoiceValuesTableRowProps {
  entry: timeApi.Invoice;
  callback: () => void;
}

export const InvoiceValuesTableRow = ({
  entry,
  callback,
}: InvoiceValuesTableRowProps) => {
  const [showForm, setShowForm] = useState(false);

  return !entry.invoiceId ? (
    <></>
  ) : (
    <ErrorBoundary>
      <TableRow key={entry.invoiceId} onClick={() => setShowForm(!showForm)}>
        <TableCell>
          <Stack>
            <Box>
              <h3>{entry.client?.name}</h3>
            </Box>
            <Box>
              <strong>Created By:</strong>
              {entry?.createdBy?.email}
            </Box>
            <Box>
              <strong>Created On:</strong>

              <Moment format="dddd MM/DD/YYYY">{entry.createdOn}</Moment>
            </Box>
            <Box>
              <strong>Invoice Date:</strong>
              <Moment format="dddd MM/DD/YYYY">{entry.invoiceDate}</Moment>
            </Box>
            <Box>
              <strong>Due Date:</strong>
              <Moment format="dddd MM/DD/YYYY">{entry.dueDate}</Moment>
            </Box>
            <Box>
              <strong>Description:</strong> {entry.descriptionOfWork}
            </Box>
            <Box>
              <strong>Amount:</strong> {entry.totalAmount}
            </Box>
            <Box>
              <strong>Amount Paid: </strong>
              {entry.amountPaid < entry.totalAmount ? (
                <span style={{ color: "red" }}>{entry.amountPaid}</span>
              ) : (
                <span style={{ color: "green" }}>{entry.amountPaid}</span>
              )}
            </Box>
            <ModalDialog
              title="Invoice Details"
              onClose={() => setShowForm(!showForm)}
              show={showForm}
            >
              <InvoiceFormEdit
                entry={entry}
                callback={callback}
                onCancel={() => setShowForm(!showForm)}
              />
            </ModalDialog>
            <InvoiceDownloadButton
              entryId={entry.invoiceId}
              callback={callback}
            />
          </Stack>
        </TableCell>
        <TableCell>
          <InvoiceDeleteButton entryId={entry.invoiceId} callback={callback} />
        </TableCell>
      </TableRow>
    </ErrorBoundary>
  );
};
