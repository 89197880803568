import { Chip } from "@mui/material";
import { useEffect, useState } from "react";

export const Clock = () => {
  const [date, setDate] = useState(new Date());
  const [timerID, setTimerID] = useState();
  const tick = () => {
    setDate(new Date());
  };

  useEffect(() => {
    setTimerID(setInterval(tick, 1000));
    return clearInterval(timerID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Chip label={<FormattedDate date={date} />} color="default" size="small" />
  );
};

function FormattedDate(props) {
  return <div>{props.date.toLocaleTimeString()}.</div>;
}
