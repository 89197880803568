import React, { useMemo, useEffect, useState } from "react";
import { InvoiceApi } from "../../api/api-invoices";
import { timeApi } from "../../api/generated/applicationClient";
import { IProviderProps } from "../Types/IProviderProps";

export const InvoiceContext = React.createContext({
  invoiceData: new Array<timeApi.Invoice>(),
  updateInvoiceContext: () => {},
});

export const InvoiceProvider = ({ children }: IProviderProps) => {
  const [invoiceData, setInvoiceData] = useState<timeApi.Invoice[]>([]);
  const datasource = useMemo(() => new InvoiceApi(), []);

  const reloadTable = async () => {
    const datasource = new InvoiceApi();
    setInvoiceData(await datasource.GetAllInvoices());
  };

  // Get data on App load
  useEffect(() => {
    reloadTable();
    console.log("running request GetAllInvoices");
  }, []);

  const invoiceContext = {
    invoiceData: invoiceData,
    updateInvoiceContext: async () => {
      // Function to pass with data incase invoiceData needs updating
      const entries = await datasource.GetAllInvoices();
      setInvoiceData(entries);
      console.log("running request updateInvoiceContext");
    },
  };

  return (
    <InvoiceContext.Provider value={invoiceContext}>
      {children}
    </InvoiceContext.Provider>
  );
};
