import authService from "./api-authorization/AuthorizeService";
import axios, { AxiosInstance } from "axios";
export class RestService {
  axiosInstance: AxiosInstance | undefined;
  user: any | undefined;
  token!: string | null;
  GetAxiosInstance = async () => {
    [this.user, this.token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);
    this.axiosInstance = axios.create({
      baseURL: `${window.location.protocol}//${window.location.host}/${
        window.location.port ? ":" + window.location.port : ""
      }/api`,
      timeout: 10000,
      headers: {
        "Authorization": `Bearer ${this.token}`,
        essUsername: this.user?.name,
      },
    });
  };
  async SendGetRequest(url: string) {
    const [user, token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);
    try {
      const res = await fetch(url, {
        headers: {
          "Authorization": `Bearer ${token}`,
          essUsername: user.name,
        },
      }).then((response) => response.json());
      return res;
    } catch (err) {
      console.log("Error: " + url + ": " + err);
    }
  }

  async SendRequest(url: string, callback: any) {
    const [user, token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);
    try {
      callback(
        await fetch(url, {
          headers: {
            "Authorization": `Bearer ${token}`,
            essUsername: user.name,
          },
        }).then((response) => response.json())
      );
    } catch (err) {
      console.log("Error: " + url + ": " + err);
    }
  }

  async SendPatchRequest(url: string, callback: any, data: any) {
    const [user, token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);
    try {
      callback(
        await axios.patch(url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            essUsername: user.name,
          },
        })
      );
    } catch (err) {
      console.log("Error: " + url + ": " + err);
    }
  }
  async SendPostRequest(url: string, callback: any, data: any) {
    const [user, token] = await Promise.all([
      authService.getUser(),
      authService.getAccessToken(),
    ]);
    try {
      callback(
        await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            essUsername: user.name,
          },
        })
      );
    } catch (err) {
      console.log("Error: " + url + ": " + err);
    }
  }
}
