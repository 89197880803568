import { CSVLink } from "react-csv";
import { ErrorBoundary } from "./ErrorBoundary";

const ExportCsvButton = (props) => {
  if (props?.data != null) {
    return (
      <ErrorBoundary displayMessageOnError="Export ability is currently disabled">
        <CSVLink
          data={props.data}
          filename={props.filename}
          headers={props?.headers}
          target="_blank"
        >
          {props.linkDisplayText}
        </CSVLink>
      </ErrorBoundary>
    );
  } else {
    return <div>loading csv button..</div>;
  }
};

export default ExportCsvButton;
